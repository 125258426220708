.profile-settings,
.profile-settings * {
  box-sizing: border-box;
}
.profile-settings {
  background: #000000;
color: #fff;
  height: 1439px;
  overflow: hidden;
}
.profile-settings2 {
  background: #000000;
  width: 1440px;
  height: 1439px;
  overflow: hidden;
}
.section-1 {
  inset: 0;
}
.rectangle-2 {
  width: 1440px;
  height: 446px;
  object-fit: cover;
}
.rectangle-3 {
  background: rgba(3, 4, 7, 0.8);
  width: 1440px;
  height: 446px;
}
.profile-details {
  width: 327px;
  height: 164px;
}
.cse-maverick {
  color: #d3d9d5;
  text-align: left;
  font-family: sans-serif,"Montserrat-SemiBold";
  font-size: 20px;
  font-weight: 600;

}
.rectangle-28 {
  border-radius: 5px;
  width: 33.64%;
  height: 67.07%;
  object-fit: cover;
}
.vector {
  width: 7.65%;
  height: 13.41%;
  overflow: visible;
}
.user-details {
  inset: 0;
}
.online {
  color: #d3d9d5;
  text-align: left;
  font-family:sans-serif,'Montserrat' !important;
  font-size: 12px;
  font-weight: 400;
  width: 23.85%;
  height: 17.68%;
  display: flex;
  align-items: center;
  justify-content: flex-start;
}
.joined-2-years-ago {
  color: #d3d9d5;
  text-align: left;
  font-family:sans-serif,'Montserrat' !important;
  font-size: 12px;
  font-weight: 400;
  width: 41.59%;
  height: 17.68%;
  display: flex;
  align-items: center;
  justify-content: flex-start;
}
.vector2 {
  width: 4.59%;
  height: 9.15%;
  overflow: visible;
}
.vector3 {
  width: 4.59%;
  height: 9.15%;
  overflow: visible;
}
.group-12 {
  width: 337px;
  height: 53px;
  position: static;
}
.profile-settings3 {
  color: #d3d9d5;
  text-align: center;
  font-family:sans-serif,'Montserrat' !important;
  font-size: 40px;
  font-weight: 700;
  width: 337px;
  height: 53px;
  display: flex;
  align-items: center;
  justify-content: center;
}
.header-after-login {
  width: 100%;
  height: 6.18%;
}
.rectangle-12 {
  background: rgba(0, 0, 0, 0.8);
  width: 100%;
  height: 100%;
}
.logo-03-1 {
  width: 4.15%;
  height: 67.42%;
  object-fit: cover;
}
.about-us {
  color: #ffffff;
  text-align: center;
  font-family:sans-serif,'Montserrat' !important;
  font-size: 16px;
  font-weight: 400;
  width: 76px;
  height: 23px;
  display: flex;
  align-items: center;
  justify-content: center;
}
.language {
  width: 60px;
  height: 23px;
}
.language-group {
  position: absolute;
  inset: 0;
}
.en {
  color: #ffffff;
  text-align: center;
  font-family:sans-serif,'Montserrat' !important;
  font-size: 16px;
  font-weight: 400;
  width: 28px;
  height: 23px;
  display: flex;
  align-items: center;
  justify-content: center;
}
.globe-vector {
  width: 33.33%;
  height: 86.96%;
  overflow: visible;
}
.user-profile-dorpdown {
  width: 210px;
  height: 282px;

}
.group-2 {
  height: auto;
  overflow: visible;
}
.services-dropdown {
  width: 270px;
  height: 161px;
 }
.services {
  width: 107px;
  height: 23px;
}
.services2 {
  color: #ffffff;
  text-align: center;
  font-family:sans-serif,'Montserrat' !important;
  font-size: 16px;
  font-weight: 400;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
}
.vector4 {
  width: 9.35%;
  height: 21.74%;
  transform: translate(0px, 0px);
  overflow: visible;
}
.manage-your-account-details-and-preferences {
  color: #d3d9d5;
  text-align: center;
  font-family:sans-serif,'Montserrat' !important;
  font-size: 16px;
  font-weight: 300;
  width: 541px;
  height: 29px;
  display: flex;
  align-items: center;
  justify-content: center;
}
.footer {
  width: 1440px;
  height: 372px;
}
.rectangle-122 {
  background: #000000;
  width: 100%;
  height: 100%;
}
.by-the-traders-for-the-traders {
  color: #d3d9d5;
  text-align: left;
  font-family:sans-serif,'Montserrat' !important;
  font-size: 13px;
  font-weight: 300;
  width: 12.01%;
  height: 10.22%;
  display: flex;
  align-items: center;
  justify-content: flex-start;
}
.stock-wise-analytics-logo {
  width: 12.17%;
  height: 16.13%;
 }
.logo-05-1 {
  width: 100%;
  height: 100%;
  object-fit: cover;
}
.twitter {
  height: auto;
  overflow: visible;
}
.english {
  color: #ffffff;
  text-align: center;
  font-family:sans-serif,'Montserrat' !important;
  font-size: 16px;
  font-weight: 400;
  width: 3.54%;
  height: 6.18%;
  display: flex;
  align-items: center;
  justify-content: center;
}
.globe-vector2 {
  width: 1.39%;
  height: 5.38%;
  overflow: visible;
}
.vector5 {
  width: 0.69%;
  height: 1.34%;
  transform: translate(0px, 0px);
  overflow: visible;
}
.about-us2 {
  inset: 0;
}
.rectangle15 {
  background: rgba(255, 255, 255, 0.03);
  border-radius: 10px;
  width: 12.68%;
  height: 12.37%;
}
.about-us3 {
  color: #ffffff;
  text-align: center;
  font-family:sans-serif,'Montserrat' !important;
  font-size: 16px;
  font-weight: 400;
  width: 5.97%;
  height: 6.18%;
  display: flex;
  align-items: center;
  justify-content: center;
}
.contact-us {
  position: absolute;
  inset: 0;
}
.rectangle-152 {
  background: rgba(255, 255, 255, 0.03);
  border-radius: 10px;
  width: 8.68%;
  height: 12.37%;
}
.contact-us2 {
  color: #ffffff;
  text-align: center;
  font-family:sans-serif,'Montserrat' !important;
  font-size: 16px;
  font-weight: 400;
  width: 6.81%;
  height: 5.11%;
  display: flex;
  align-items: center;
  justify-content: center;
}
.for-business {
  position: absolute;
  inset: 0;
}
.rectangle-153 {
  background: rgba(255, 255, 255, 0.03);
  border-radius: 10px;
  width: 9.93%;
  height: 12.37%;
  position: absolute;
}
.for-business2 {
  color: #ffffff;
  text-align: center;
  font-family:sans-serif,'Montserrat' !important;
  font-size: 16px;
  font-weight: 400;
  width: 8.06%;
   height: 5.11%;
  display: flex;
  align-items: center;
  justify-content: center;
}
.image-11 {
  width: 37.36%;
  height: 18.55%;
   object-fit: cover;
}
.section-12 {
  width: 1440px;
  height: 621px;
}
.section-1-2 {
  background: rgba(255, 255, 255, 0.02);
  width: 720px;
  height: 621px;
}
.private-details {
  color: #ffffff;
  text-align: left;
  font-family:sans-serif,'Montserrat' !important;
  font-size: 18px;
  font-weight: 600;
  width: 178px;
  height: 43px;
  display: flex;
  align-items: center;
  justify-content: flex-start;
}
.note-this-information-will-not-be-displayed-in-public {
  color: #ffffff;
  text-align: left;
  font-family:sans-serif,'Montserrat' !important;
  font-size: 14px;
  font-weight: 300;
  width: 477px;
  height: 43px;
  display: flex;
  align-items: center;
  justify-content: flex-start;
}
.email {
  color: #ffffff;
  text-align: left;
  font-family:sans-serif,'Montserrat' !important;
  font-size: 16px;
  font-weight: 400;
  width: 157px;
  height: 43px;
  display: flex;
  align-items: center;
  justify-content: flex-start;
}
.rectangle-154 {
  background: rgba(255, 255, 255, 0.1);
  border-radius: 5px;
  width: 129px;
  height: 36px;
}
.change-email {
  color: #ffffff;
  text-align: center;
  font-family:sans-serif,'Montserrat' !important;
  font-size: 14px;
  font-weight: 400;
  width: 99px;
  height: 23px;
  display: flex;
  align-items: center;
  justify-content: center;
}
.password {
  color: #ffffff;
  text-align: left;
  font-family:sans-serif,'Montserrat' !important;
  font-size: 16px;
  font-weight: 400;
  width: 157px;
  height: 43px;
  display: flex;
  align-items: center;
  justify-content: flex-start;
}
.rectangle-16 {
  background: rgba(255, 255, 255, 0.1);
  border-radius: 5px;
  width: 161px;
  height: 36px;
}
.change-password {
  color: #ffffff;
  text-align: left;
  font-family:sans-serif,'Montserrat' !important;
  font-size: 14px;
  font-weight: 400;
  width: 130px;
  height: 23px;
  display: flex;
  align-items: center;
  justify-content: flex-start;
}
.secure-your-account {
  color: #ffffff;
  text-align: left;
  font-family:sans-serif,'Montserrat' !important;
  font-size: 16px;
  font-weight: 400;
  width: 172px;
  height: 43px;
  display: flex;
  align-items: center;
  justify-content: flex-start;
}
.rectangle-17 {
  background: rgba(255, 255, 255, 0.1);
  border-radius: 5px;
  width: 255px;
  height: 36px;
}
.first-name {
  color: #ffffff;
  text-align: left;
  font-family:sans-serif,'Montserrat' !important;
  font-size: 16px;
  font-weight: 400;
  width: 172px;
  height: 43px;
  display: flex;
  align-items: center;
  justify-content: flex-start;
}
.rectangle-18 {
  border-radius: 5px;
  border-style: solid;
  border-color: rgba(255, 255, 255, 0.25);
  border-width: 1px;
  width: 428px;
  height: 36px;
}
.last-name {
  color: #ffffff;
  text-align: left;
  font-family:sans-serif,'Montserrat' !important;
  font-size: 16px;
  font-weight: 400;
  width: 172px;
  height: 43px;
  display: flex;
  align-items: center;
  justify-content: flex-start;
}
.rectangle-19 {
  border-radius: 5px;
  border-style: solid;
  border-color: rgba(255, 255, 255, 0.25);
  border-width: 1px;
  width: 428px;
  height: 36px;
}
.phone {
  color: #ffffff;
  text-align: left;
  font-family:sans-serif,'Montserrat' !important;
  font-size: 16px;
  font-weight: 400;
  width: 172px;
  height: 43px;
  display: flex;
  align-items: center;
  justify-content: flex-start;
}
.rectangle-20 {
  border-radius: 5px;
  border-style: solid;
  border-color: rgba(255, 255, 255, 0.25);
  border-width: 1px;
  width: 202px;
  height: 36px;
}
.enable-2-factor-authentication {
  color: #ffffff;
  text-align: left;
  font-family:sans-serif,'Montserrat' !important;
  font-size: 14px;
  font-weight: 400;
  width: 224px;
  height: 23px;
  display: flex;
  align-items: center;
  justify-content: flex-start;
}
.rectangle-21 {
  border-radius: 5px;
  border-style: solid;
  border-color: #ffffff;
  border-width: 1px;
  width: 203px;
  height: 36px;

}
.add-phone-number {
  color: #ffffff;
  text-align: center;
  font-family:sans-serif,'Montserrat' !important;
  font-size: 14px;
  font-weight: 400;
  width: 170px;
  height: 23px;
  display: flex;
  align-items: center;
  justify-content: center;
}
.alternative-email-for-alerts {
  color: #ffffff;
  text-align: left;
  font-family:sans-serif,'Montserrat' !important;
  font-size: 16px;
  font-weight: 400;
  width: 172px;
  height: 43px;
  display: flex;
  align-items: center;
  justify-content: flex-start;
}
.rectangle-22 {
  border-radius: 5px;
  border-style: solid;
  border-color: rgba(255, 255, 255, 0.25);
  border-width: 1px;
  width: 202px;
  height: 36px;
}
.rectangle-23 {
  border-radius: 5px;
  border-style: solid;
  border-color: #ffffff;
  border-width: 1px;
  width: 203px;
  height: 36px;
}
.add-email {
  color: #ffffff;
  text-align: center;
  font-family:sans-serif,'Montserrat' !important;
  font-size: 14px;
  font-weight: 400;
  width: 170px;
  height: 23px;
  display: flex;
  align-items: center;
  justify-content: center;
}
.email-address-that-can-be-used-to-send-alerts {
  color: #ffffff;
  text-align: left;
  font-family:  sans-serif,"Montserrat-Light";
  font-size: 14px;
  font-weight: 300;
  width: 422px;
  height: 43px;
  display: flex;
  align-items: center;
  justify-content: flex-start;
}
.rectangle-24 {
  background: rgba(255, 255, 255, 0.1);
  border-radius: 5px;
  width: 203px;
  height: 36px;
}
.save-changes {
  color: #ffffff;
  text-align: center;
  font-family:sans-serif,'Montserrat' !important;
  font-size: 16px;
  font-weight: 400;
  width: 170px;
  height: 23px;
  display: flex;
  align-items: center;
  justify-content: center;
}
.section-1-1 {
  background: rgba(255, 255, 255, 0.02);
  width: 720px;
  height: 621px;
}
.public-details {
  color: #ffffff;
  text-align: left;
  font-family: "Montserrat-SemiBold", sans-serif;
  font-size: 18px;
  font-weight: 600;
   width: 157px;
  height: 43px;
  display: flex;
  align-items: center;
  justify-content: flex-start;
}
.note-this-information-will-be-displayed-in-public-and-visible-for-all-users {
  color: #ffffff;
  text-align: left;
  font-family:  sans-serif,"Montserrat-Light";
  font-size: 14px;
  font-weight: 300;
  width: 573px;
  height: 43px;
  display: flex;
  align-items: center;
  justify-content: flex-start;
}
.name-nickname {
  color: #ffffff;
  text-align: left;
  font-family:sans-serif,'Montserrat' !important;
  font-size: 16px;
  font-weight: 400;
  width: 157px;
  height: 43px;
  display: flex;
  align-items: center;
  justify-content: flex-start;
}
.rectangle-155 {
  border-radius: 5px;
  border-style: solid;
  border-color: #ffffff;
  border-width: 1px;
  width: 165px;
  height: 36px;
  }
.cse-maverick2 {
  color: #d3d9d5;
  text-align: left;
  font-family:sans-serif,'Montserrat' !important;
  font-size: 14px;
  font-weight: 400;
   width: 106px;
  height: 23px;
  display: flex;
  align-items: center;
  justify-content: flex-start;
}
.country {
  color: #ffffff;
  text-align: left;
  font-family:sans-serif,'Montserrat' !important;
  font-size: 16px;
  font-weight: 400;
  width: 157px;
  height: 43px;
  display: flex;
  align-items: center;
  justify-content: flex-start;
}
.rectangle-25 {
  border-radius: 5px;
  border-style: solid;
  border-color: #ffffff;
  border-width: 1px;
  width: 165px;
  height: 36px;
  position: absolute;
  left: 262px;
  top: 186px;
}
.sri-lanka {
  color: #d3d9d5;
  text-align: left;
  font-family:sans-serif,'Montserrat' !important;
  font-size: 14px;
  font-weight: 400;
   width: 106px;
  height: 23px;
  display: flex;
  align-items: center;
  justify-content: flex-start;
}
.profile-picture-avatar-logo {
  color: #ffffff;
  text-align: left;
  font-family:sans-serif,'Montserrat' !important;
  font-size: 16px;
  font-weight: 400;
  width: 172px;
  height: 43px;
  display: flex;
  align-items: center;
  justify-content: flex-start;
}
.jpg-gif-or-png-files-supported-max-file-size-700-kb {
  color: #ffffff;
  text-align: left;
  font-family:  sans-serif,"Montserrat-Light";
  font-size: 12px;
  font-weight: 300;
  width: 198px;
  height: 32px;
  display: flex;
  align-items: center;
  justify-content: flex-start;
}
.rectangle-29 {
  background: rgba(255, 255, 255, 0.1);
  border-radius: 5px;
  width: 203px;
  height: 36px;
}
.rectangle-282 {
  border-radius: 5px;
  width: 110px;
  height: 110px;
  object-fit: cover;
}
.upload-photo {
  color: #ffffff;
  text-align: center;
  font-family:sans-serif,'Montserrat' !important;
  font-size: 16px;
  font-weight: 400;
   width: 138px;
  height: 23px;
  display: flex;
  align-items: center;
  justify-content: center;
}
.rectangle-26 {
  background: rgba(255, 255, 255, 0.1);
  border-radius: 5px;
  width: 203px;
  height: 36px;
}
.delete-photo {
  color: #ffffff;
  text-align: center;
  font-family:sans-serif,'Montserrat' !important;
  font-size: 16px;
  font-weight: 400;
  width: 138px;
  height: 23px;
  display: flex;
  align-items: center;
  justify-content: center;
}
.vector6 {
  width: 3.47%;
  height: 3.54%;
  overflow: visible;
}
.rectangle-242 {
  background: rgba(255, 255, 255, 0.1);
  border-radius: 5px;
  width: 203px;
  height: 36px;
}
.save-changes2 {
  color: #ffffff;
  text-align: center;
  font-family:sans-serif,'Montserrat' !important;
  font-size: 16px;
  font-weight: 400;
  display: flex;
  align-items: center;
  justify-content: center;
}
.ChangeEmail {
  width: auto; height: 36px; background: rgba(255, 255, 255, 0.10); border-radius: 5px;color: #ffffff;
    }
    select {
      background: #404040 url('data:image/svg+xml,<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 384 512"><path fill="%23ffffff" d="M192 384c-8.188 0-16.38-3.125-22.62-9.375l-160-160c-12.5-12.5-12.5-32.75 0-45.25s32.75-12.5 45.25 0L192 306.8l137.4-137.4c12.5-12.5 32.75-12.5 45.25 0s12.5 32.75 0 45.25l-160 160C208.4 380.9 200.2 384 192 384z"/></svg>') right 1rem center/8px 10px no-repeat;
  }
  
  .custom-file-upload {
    border: 1px solid #ccc;
    display: inline-block;
    padding: 2px 4px;
    color: #000;
    cursor: pointer;
    -webkit-border-radius: 5px;
    -moz-border-radius: 5px;
    border: 1px dashed #BBB;
    text-align: center;
    background-color: #DDD;
    cursor: pointer;
  }
  label[for="file-input"] {
    display: block;
    margin-bottom: 1em;
    font-size: 1em;
    color: #fff;
    opacity: .9;
    font-weight: bold;
  }
  
  input[type="file"] {
    cursor: pointer !Important;
  }
  input[type="file"]::-webkit-file-upload-button {
    border: none;
    padding: 5px 12px;
    background: #9e2baf;
    color: #fff;
    font-size: 1em;
    transition: all .4s;
    cursor: pointer;
    border-radius: 20px;
  }
  input[type="file"]::-ms-browse {
    border: none;
    padding: 5px 12px;
    background: #9e2baf;
    color: #fff;
    font-size: 1em;
    transition: all .4s;
    cursor: pointer;
    border-radius: 20px;
  }

  .form-control {
    margin: 0;
    padding: 0.5rem;
  }
/*   
  .form-select {
    margin: 0;
    padding: 0.5rem;
  } */
  input[type="text"] {
    cursor: pointer !Important;
    margin: 0;
    padding: 0.2rem;
    font-family: sans-serif,"Montserrat" !important;
  }
  input[type="email"] {
  font-family: sans-serif,"Montserrat" !important;
  padding: 0.2rem  !important;
  }

  input[type="password"] {
    font-family: sans-serif,"Montserrat" !important;
    padding: 0.2rem  !important;
    }
  .spancenter
  {
    margin:auto; 
    display:table;
  }
  #InputEmail {
    float:left;
    width:180px;
    -ms-flex: 0 0 100%;
    flex: 0 0 100%;
    max-width: 100%;
    font-family: sans-serif,"Montserrat" !important;
 }
 
 #changeemail {
    float: left;
    width: calc(100% - 180px);
    background-color:#6c757d;
    -ms-flex: 0 0 60%;
    flex: 0 0 60%;
    max-width: 60%;
    font-family: sans-serif,"Montserrat" !important;
 }
 #altEmail {
  float:left;
  width:180px;
  -ms-flex: 100% 0 0;
  flex: 100% 0 0;
  max-width: 100%;
}
.form-horizontal{
  display:block;
  width:70%;
  margin:0 auto;
  float:left;
}
.form-horizontal-right{
  display:block;
  width:85%;
  margin:0 auto;
  float:right;
}


.Appf {
  border: 1px dashed #3697e0;
  border-radius: 5px;
  text-align: center !important;
  padding: 10px 10px;
  width: 150px;
}
.labelf {
  cursor: pointer;
  text-align: center;
}

.labelf h5 {
  color: #ffffff;
  font-size: 14px;
  text-align: center;
}

.upload__icon {
  font-size: 30px;
  color: #ffffff;
}

.labelf p {
  font-size: 10px;
  color: rgb(234, 238, 241);
}

.inputf {
  display: none;
}
.cntrycss
{
  z-index: 2; 
  position: relative;
  cursor: pointer;
  padding: 4px;
  margin: 0;
  display: inline-flex;
  align-items: center;
  justify-content: center;  
  font-family:sans-serif,'Montserrat' !important;
}
.browser {
  margin: 0 !important;
  padding: 0 !important;
  height: auto !important;
}

.fs-60
{
  color: #D3D9D5 !important;
  font-family:sans-serif,'Montserrat' !important;
  font-size: 12px !important;
  font-style: normal !important;
  font-weight: 400 !important;
  line-height: normal !important;
}
Appfdi {
  border: 1px dashed #3697e0;
  border-radius: 5px;
  padding: 10px 10px;
  text-align: center;
  width: 120px;
  height: 120px;
}
.font-family-sans-serif {
  font-family:sans-serif,'Montserrat' !important;
}