.divider:after,
.divider:before {
content: "";
flex: 1;
height: 1px;
background: #eee;
}
.h-custom {
height: calc(100% - 73px);
}
@media (max-width: 450px) {
.h-custom {
height: 100%;
}
}
.login,
.login * {
  box-sizing: border-box;
  left:10%
}
.login {
  height: 800px;
  position: relative;
  overflow: hidden;
}
body {
  overflow: auto; /* Hide scrollbars */
}
.login-form{
  -ms-flex-pack: center !important;
  /* justify-content: center !important; */
}
.login .stock-wise-analytics-logo-instance {
  width: 336.02px !important;
  height: 115px !important;
  position: absolute !important;
  left: 914px !important;
  top: 55px !important;
}
.login .rectangle-2 {
  border-radius: 0px 20px 20px 0px;
  width: 720px;
  height: 800px;
  position: absolute;
  left: calc(50% - 720px);
  top: 0px;
}
 
.login .rectangle-3 {
  background: rgba(3, 4, 7, 0.8);
  border-radius: 0px 20px 20px 0px;
  width: 720px;
  height: 800px;
  position: absolute;
  left: calc(50% - 720px);
  top: 0px;
}
.login .sign-up {
  color: #d3d9d5;
  text-align: center;
  font-family: sans-serif, "Montserrat-Bold";
  font-size: 32px;
  font-weight: 700;
  position: absolute;
  right: 18.82%;
  left: 69.1%;
  width: 12.08%;
  bottom: 70.69%;
  top: 26.87%;
  height: 2.44%;
  display: flex;
  align-items: center;
  justify-content: center;
}
.login
  .sign-up-to-access-your-account-and-unlock-a-world-of-powerful-tools-and-insights-for-your-stock-market-journey {
  color: #d3d9d5;
  text-align: center;
  font-family: "-", sans-serif;
  font-size: 16px;
  font-weight: 400;
  position: absolute;
  right: 9.72%;
  left: 60%;
  width: 30.28%;
  bottom: 61.38%;
  top: 30.88%;
  height: 7.75%;
  display: flex;
  align-items: center;
  justify-content: center;
}
.sign-up-to-access-your-account-and-unlock-a-world-of-powerful-tools-and-insights-for-your-stock-market-journey-span {
  color: #d3d9d5;
  font-family:  sans-serif,"Montserrat-Light";
  font-size: 16px;
  font-weight: 300;
}
.sign-up-to-access-your-account-and-unlock-a-world-of-powerful-tools-and-insights-for-your-stock-market-journey-span2 {
  color: #d3d9d5;
  font-family: sans-serif, "Montserrat-Bold";
  font-size: 16px;
  font-weight: 700;
}
.sign-up-to-access-your-account-and-unlock-a-world-of-powerful-tools-and-insights-for-your-stock-market-journey-span3 {
  color: #d3d9d5;
  font-family:  sans-serif,"Montserrat-Light";
  font-size: 16px;
  font-weight: 300;
}
.sign-up-to-access-your-account-and-unlock-a-world-of-powerful-tools-and-insights-for-your-stock-market-journey-span4 {
  color: #d3d9d5;
  font-family: sans-serif, "Montserrat-Bold";
  font-size: 16px;
  font-weight: 700;
}
.sign-up-to-access-your-account-and-unlock-a-world-of-powerful-tools-and-insights-for-your-stock-market-journey-span5 {
  color: #d3d9d5;
  font-family:  sans-serif,"Montserrat-Light";
  font-size: 16px;
  font-weight: 300;
}
.login .sign-up2 {
  position: absolute;
  inset: 0;
}
.login .rectangle-27 {
  background: #d9d9d9;
  border-radius: 10px;
  border-style: solid;
  border-color: rgba(255, 255, 255, 0.5);
  border-width: 1px;
  width: 355px;
  height: 42px;
  position: absolute;
  left: calc(50% - -185px);
  top: 623px;
}
.login .group-13 {
  width: 215px;
  height: 31px;
  position: static;
}
.sign-up-with-google {
  color: #000000;
  text-align: left;
  font-family: sans-serif,"Montserrat",;
  font-size: 16px;
  line-height: 31px;
  font-weight: 400;
  position: absolute;
  width: 50% !important;
  display: flex;
  align-items: center;
  justify-content: flex-start;
}
.sign-up-with-google-span {
  color: #000000;
  font-family: sans-serif,"Montserrat",;
  font-size: 16px;
  line-height: 31px;
  font-weight: 400;
  display: flex !important;
  align-items: center !important;
  justify-content: flex-start !important;
}
.sign-up-with-google-span2 {
  color: #000000;
  font-family: sans-serif,"Montserrat",;
  font-size: 16px;
  line-height: 31px;
  font-weight: 400;
}
.sign-up-with-google-span3 {
  color: #000000;
  font-family: sans-serif,"Montserrat",;
  font-size: 16px;
  line-height: 31px;
  font-weight: 400;
}
.login .google {
  width: 1.46%;
  height: 2.62%;
  position: absolute;
  right: 30.07%;
  left: 68.47%;
  bottom: 18.12%;
  top: 79.25%;
  overflow: visible;
}
.login .rectangle-272 {
  background: #d9d9d9;
  border-radius: 10px;
  border-style: solid;
  border-color: rgba(255, 255, 255, 0.5);
  border-width: 1px;
  width: 355px;
  height: 42px;
  position: absolute;
  left: calc(50% - -185px);
  top: 671px;
}
.login .sign-up-with-apple {
  color: #000000;
  text-align: left;
  font-family: sans-serif,"Montserrat",;
  font-size: 16px;
  line-height: 31px;
  font-weight: 400;
  position: absolute;
  left: calc(50% - -310px);
  top: 676px;
  width: 163px;
  display: flex;
  align-items: center;
  justify-content: flex-start;
}
.sign-up-with-apple-span {
  color: #000000;
  font-family: sans-serif,"Montserrat",;
  font-size: 16px;
  line-height: 31px;
  font-weight: 400;
}
.sign-up-with-apple-span2 {
  color: #000000;
  font-family: sans-serif,"Montserrat",;
  font-size: 16px;
  line-height: 31px;
  font-weight: 400;
}
.sign-up-with-apple-span3 {
  color: #000000;
  font-family: sans-serif,"Montserrat",;
  font-size: 16px;
  line-height: 31px;
  font-weight: 400;
}
.login .vector {
  width: 1.25%;
  height: 2.62%;
  position: absolute;
  right: 30.28%;
  left: 68.47%;
  bottom: 12.25%;
  top: 85.12%;
  overflow: visible;
}
.login .email-and-password {
  position: absolute;
  inset: 0;
  color: rgba(255, 255, 255, 0.49);

font-family:  sans-serif,'Montserrat';
font-size: 16px;
font-style: normal;
font-weight: 400;
line-height: 31px; /* 193.75% */
text-transform: capitalize;
}
.login .sign-up3 {
  width: 355px;
  height: 42px;
  position: static;
}
.login .rectangle-26 {
  background: #d9d9d9;
  border-radius: 10px;
  border-style: solid;
  border-color: rgba(255, 255, 255, 0.5);
  border-width: 1px;
  width: 355px;
  height: 42px;
  position: absolute;
  left: calc(50% - -185px);
  top: 498px;
}
.login .sign-up4 {
  color: #000000;
  text-align: center;
  font-family: sans-serif,"Montserrat",;
  font-size: 16px;
  line-height: 31px;
  font-weight: 400;
  position: absolute;
  left: calc(50% - -287px);
  top: 504px;
  width: 151px;
  display: flex;
  align-items: center;
  justify-content: center;
}
.login .rectangle-24 {
  border-radius: 10px;
  border-style: solid;
  border-color: rgba(255, 255, 255, 0.5);
  border-width: 1px;
  width: 355px;
  height: 42px;
  position: absolute;
  left: calc(50% - -185px);
  top: 354px;
}
.login .email-address {
  color: rgba(255, 255, 255, 0.49);
  text-align: left;
  font-family: sans-serif,"Montserrat",;
  font-size: 16px;
  line-height: 31px;
  font-weight: 400;
  position: absolute;
  left: calc(50% - -197px);
  top: 359px;
  width: 151px;
  display: flex;
  align-items: center;
  justify-content: flex-start;
}
.login .rectangle-25 {
  border-radius: 10px;
  border-style: solid;
  border-color: rgba(255, 255, 255, 0.5);
  border-width: 1px;
  width: 355px;
  height: 42px;
  position: absolute;
  left: calc(50% - -185px);
  top: 408px;
}
.login .password {
  color: rgba(255, 255, 255, 0.49);
  text-align: left;
  font-family: sans-serif,"Montserrat",;
  font-size: 16px;
  line-height: 31px;
  font-weight: 400;
  position: absolute;
  left: calc(50% - -197px);
  top: 414px;
  width: 151px;
  display: flex;
  align-items: center;
  justify-content: flex-start;
}
.login .vector2 {
  width: 1.53%;
  height: 1.88%;
  position: absolute;
  right: 13.4%;
  left: 85.07%;
  bottom: 45.38%;
  top: 52.75%;
  overflow: visible;
}
.login .forgot-password {
  color: rgba(255, 255, 255, 0.49);
  text-align: left;
  font-family: "-", sans-serif;
  font-size: 14px;
  line-height: 31px;
  font-weight: 400;
  position: absolute;
  left: calc(50% - -185px);
  top: 452px;
  width: 131px;
  display: flex;
  align-items: center;
  justify-content: flex-start;
}
.forgot-password-span {
  color: rgba(255, 255, 255, 0.49);
  font-family: "Montserrat-Medium", sans-serif;
  font-size: 14px;
  line-height: 31px;
  font-weight: 500;
}
.forgot-password-span2 {
  color: rgba(255, 255, 255, 0.49);
  font-family:  sans-serif,"Montserrat-Light";
  font-size: 14px;
  line-height: 31px;
  font-weight: 300;
}
.login .click-here {
  color: rgba(255, 255, 255, 0.49); font-size: 14px !important; font-family:  sans-serif,'Montserrat'; font-weight: 300; text-decoration: underline; line-height: 31px; word-wrap: break-word;
}
.login .already-have-an-account {
  color: rgba(255, 255, 255, 0.49);
  text-align: center;
  font-family: "-", sans-serif;
  font-size: 14px;
  line-height: 31px;
  font-weight: 400;
  position: absolute;
  left: calc(50% - -241px);
  top: 719px;
  width: 197px;
  display: flex;
  align-items: center;
  justify-content: center;
}
.already-have-an-account-span {
  color: rgba(255, 255, 255, 0.49);
  font-family: "Montserrat-SemiBold", sans-serif;
  font-size: 14px;
  line-height: 31px;
  font-weight: 600;
}
.already-have-an-account-span2 {
  color: rgba(255, 255, 255, 0.49);
  font-family: sans-serif,"Montserrat",;
  font-size: 14px;
  line-height: 31px;
  font-weight: 400;
}
.login .or {
  position: absolute;
  inset: 0;
}
.login .or2 {
  color: rgba(255, 255, 255, 0.49);
  text-align: center;
  font-family: "Lato-Regular", sans-serif;
  font-size: 16px;
  line-height: 31px;
  font-weight: 400;
  position: absolute;
  left: calc(50% - -286px);
  top: 566px;
  width: 151px;
  display: flex;
  align-items: center;
  justify-content: center;
}
.login .line-1 {
  border-style: solid;
  border-color: #ffffff;
  border-width: 1px 0 0 0;
  width: 155px;
  height: 0px;
  position: absolute;
  left: 905px;
  top: 582px;
}
.login .line-2 {
  border-style: solid;
  border-color: #ffffff;
  border-width: 1px 0 0 0;
  width: 155px;
  height: 0px;
  position: absolute;
  left: 1105px;
  top: 582px;
}
.login .log-in {
  color: rgba(255, 255, 255, 0.49);
  text-align: center;
  font-family: sans-serif,"Montserrat",;
  font-size: 14px;
  line-height: 31px;
  font-weight: 400;
  text-decoration: underline;
  position: absolute;
  left: calc(50% - -437px);
  top: 719px;
  width: 48px;
  display: flex;
  align-items: center;
  justify-content: center;
}
.error-setup {
  color: #d3d9d5;
  text-align: center;
  font-family: "Montserrat";
  font-size: 15px;
  font-weight: 700;
  position: absolute;
  width: 100%;
  position: absolute;
  right: 9.72%;
  left: 30%;
  width: 30.28%;
  bottom: 61.38%;
  top: 30.88%;
  height: 2.44%;
  display: flex;
  align-items: center;
  justify-content: center;
}
.error-message {
  text-align: center;
  color: red;
}
input::placeholder {
  color: rgb(137, 136, 136) !important;
  font-family: "Montserrat" !important;
}
input[type="email"]{
  padding-inline: 45px;
  color: rgba(255, 255, 255, 0.49); font-size: 16px; font-family:  sans-serif,'Montserrat'; font-weight: 400;  line-height: 31px; word-wrap: break-word;
}
input[type="password"]{
  padding-inline: 45px;
  color: rgba(255, 255, 255, 0.49); font-size: 16px; font-family:  sans-serif,'Montserrat'; font-weight: 400;  line-height: 31px; word-wrap: break-word;
}
.form-horizontal{
  display:block;
  width:50%;
  margin:0 auto;
}

.Logo051
{
  width: 336;
   height: 115;
   display: flex;
justify-content: center;
align-items: center;
flex-shrink: 0;
align-self: stretch;
}
.hr-text {
  border: 0;
  font-family: "Montserrat";
  line-height: 1em;
  position: relative;
  text-align: center;
  height: 1.5em;
  font-size: 14px;
 color: #ffffff;
text-align: center;
font-style: normal;
font-weight: 400;
width: 50%;
}
.text-fpn
{
  color: rgba(255, 255, 255, 0.49); font-size: 14px !important; font-family:  sans-serif,'Montserrat'; font-weight: 600; line-height: 31px; word-wrap: break-word;
}
.hr-text::before {
    content: "";
    background: #ffffff;
    position: absolute;
    left: 0;
    top: 50%;
    width: 100%;
    height: 2px;
}

.hr-text::after {
    content: attr(data-content);
    position: relative;
    padding: 0 7px;
    line-height: 1.5em;
    color: white;
    background-color: #1a1a1a;
    font-family:  sans-serif,'Montserrat';
}
.hover:hover {
  cursor:pointer;
}
.pointer {
  cursor:pointer;
}
.SignUp
{
  display: flex;
flex-direction: column;
justify-content: center;
flex: 1 0 0;
align-self: stretch;
color: #D3D9D5;
text-align: center;
font-family:  sans-serif,'Montserrat';
font-size: 32px;
font-style: normal;
font-weight: 700;
line-height: normal;
}



.signuptitle{
  color: #D3D9D5;
  text-align: center;
  font-family:  sans-serif,'Montserrat';
  font-size: 32px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
}
.signuplittletext{
  color: #D3D9D5;
  font-family:  sans-serif,'Montserrat';
  font-size: 16px;
  font-style: bold;
  font-weight: 700;
  line-height: normal;
}
.signuplittletextlight{
  color: rgba(255, 255, 255, 0.49);
font-family:  sans-serif,'Montserrat';
font-size: 16px;
font-style: normal;
font-weight: 700;
line-height: normal;
}
.inputfld
{
  display: flex;
width: auto;
height: 41px;
justify-content: center;
align-items: center;
gap: 10px;
  border-radius: 10px;
  border: 1px solid rgba(255, 255, 255, 0.50); 
  color: rgba(255, 255, 255, 0.49);
  background-color: "#000000";
font-family:  sans-serif,'Montserrat';
font-size: 16px;
font-style: normal;
font-weight: 400;
line-height: 31px; /* 193.75% */
text-transform: capitalize;

}
/* .form-textbox {
  color: black !important;
  } */

/* input[value] {
  background-color: #000000 !important;
  background-clip: padding-box;
  border: 1px solid #ced4da;
  border-radius: 0.25rem;
} */

#InputEmail {
  float:left;
  width:180px;
  -ms-flex: 0 0 100%;
  flex: 0 0 100%;
  max-width: 100%;
}
.form-controll {
  display: block;
  width: 100%;
  height: calc(1.5em + 0.75rem + 2px);
  padding: 0.375rem 0.75rem;
  font-family:  sans-serif,'Montserrat';
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 1.5;
  color: #c6cacd;
  background-color: #000000;
  background-clip: padding-box;
  border: 1px solid #ced4da;
  border-radius: 0.5rem;
  -webkit-transition: border-color 0.10s ease-in-out, -webkit-box-shadow 0.10s ease-in-out;
  transition: border-color 0.10s ease-in-out, -webkit-box-shadow 0.10s ease-in-out;
  -o-transition: border-color 0.10s ease-in-out, box-shadow 0.10s ease-in-out;
  transition: border-color 0.10s ease-in-out, box-shadow 0.105s ease-in-out;
  transition: border-color 0.10s ease-in-out, box-shadow 0.10s ease-in-out, -webkit-box-shadow 0.10s ease-in-out;
}

.form-control2 {
  border: 1px solid #ced4da;
  border-radius: 0.25rem;
  line-height: 1.5;
  transition: background-color 0s 600000s, color 0s 600000s !important;
 }
.form-controll:focus{

  background-color: #000000;
  box-shadow: none;
  color: #fff;
  border-color: #4f63e7;
 }

 input:-webkit-autofill,
  input:-webkit-autofill:focus {
    transition: background-color 0s 600000s, color 0s 600000s !important;
  }

  .pwd{
    position: relative;
    width:60%;
  }
  .signupp{
    position: relative;
  }
  .p-viewer {
    z-index: 9999;
    position: absolute;
    top: 1%;
    right: 0;
    }
  .input-group .btn {
    border: 0;
    border-radius: 0.5rem;
  }
 
  .imgblur
  {
    width: 100%;
    filter: brightness(20%);
  }
.forgettext
{
  color: #D3D9D5;
  font-family:  sans-serif,'Montserrat';
  font-size: 16px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
}
.login-container {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh;
  background-color: #f4f4f4;
}

.login-box {
  background-color: #fff;
  padding: 30px;
  border-radius: 10px;
  box-shadow: 0 4px 12px rgba(0, 0, 0, 0.1);
  width: 400px;
}

.form-title {
  text-align: center;
  margin-bottom: 20px;
  color: #333;
}

.form-control {
  width: 100%;
  padding: 10px;
  margin-bottom: 10px;
  border: 1px solid #ddd;
  border-radius: 5px;
}

.password-field {
  position: relative;
}

.toggle-password {
  position: absolute;
  top: 50%;
  right: 10px;
  transform: translateY(-50%);
  background: none;
  border: none;
}

.btn-primary {
  background-color: #28a745;
  color: #fff;
  border: none;
  padding: 10px;
  border-radius: 5px;
  cursor: pointer;
}

.divider {
  margin: 20px 0;
}

.stock-wise-analytics-logo,
.stock-wise-analytics-logo * {
box-sizing: border-box;
}
.stock-wise-analytics-logo {
align-self: stretch;
flex-shrink: 0;
height: 115px;
position: relative;
}
.StockWiseAnalyticsLogo
{
width: 336;
 height: 115; justify-Content: center; align-Items: center; display: inline-flex;
}
.sign-up {
    color: #d3d9d5;
    text-align: center;
    font-family: 'Montserrat-Bold', sans-serif;
    font-size: 32px;
    font-weight: 700;
    position: relative;
    align-self: stretch;
    flex: 1;
    display: flex;
    align-items: center;
    justify-content: center;
}
.sign-up-to-access-your-account-and-unlock-a-world-of-powerful-tools-and-insights-for-your-stock-market-journey,
.sign-up-to-access-your-account-and-unlock-a-world-of-powerful-tools-and-insights-for-your-stock-market-journey * {
    box-sizing: border-box;
}

.sign-up-to-access-your-account-and-unlock-a-world-of-powerful-tools-and-insights-for-your-stock-market-journey {
    color: #d3d9d5;
    text-align: center;
    font-family: '-', sans-serif;
    font-size: 16px;
    font-weight: 400;
    position: relative;
    align-self: stretch;
    flex: 1;
    display: flex;
    align-items: center;
    justify-content: center;
}

.sign-up-to-access-your-account-and-unlock-a-world-of-powerful-tools-and-insights-for-your-stock-market-journey-span {
    color: #d3d9d5;
    font-family: 'Montserrat-Light', sans-serif;
    font-size: 16px;
    font-weight: 300;
}

.sign-up-to-access-your-account-and-unlock-a-world-of-powerful-tools-and-insights-for-your-stock-market-journey-span2 {
    color: #d3d9d5;
    font-family: 'Montserrat-Bold', sans-serif;
    font-size: 16px;
    font-weight: 700;
}

.sign-up-to-access-your-account-and-unlock-a-world-of-powerful-tools-and-insights-for-your-stock-market-journey-span3 {
    color: #d3d9d5;
    font-family: 'Montserrat-Light', sans-serif;
    font-size: 16px;
    font-weight: 300;
}

.sign-up-to-access-your-account-and-unlock-a-world-of-powerful-tools-and-insights-for-your-stock-market-journey-span4 {
    color: #d3d9d5;
    font-family: 'Montserrat-Bold', sans-serif;
    font-size: 16px;
    font-weight: 700;
}

.sign-up-to-access-your-account-and-unlock-a-world-of-powerful-tools-and-insights-for-your-stock-market-journey-span5 {
    color: #d3d9d5;
    font-family: 'Montserrat-Light', sans-serif;
    font-size: 16px;
    font-weight: 300;
}
.frame-1000002769,
.frame-1000002769 * {
    box-sizing: border-box;
}

.frame-1000002769 {
    border-radius: 10px;
    border-style: solid;
    border-color: rgba(255, 255, 255, 0.50);
    border-width: 1px;
    padding: 5px 171px 5px 12px;
    display: flex;
    flex-direction: row;
    gap: 10px;
    align-items: center;
    justify-content: center;
    flex-shrink: 0;
    height: 41px;
    position: relative;
    overflow: hidden;
}

.email-address {
    color: rgba(255, 255, 255, 0.49);
    text-align: left;
    font-family: 'Montserrat-Regular', sans-serif;
    font-size: 16px;
    line-height: 31px;
    font-weight: 400;
    position: relative;
    align-self: stretch;
    flex: 1;
    display: flex;
    align-items: center;
    justify-content: flex-start;
}
.frame-1000002770,
.frame-1000002770 * {
    box-sizing: border-box;
}

.frame-1000002770 {
    border-radius: 10px;
    border-style: solid;
    border-color: rgba(255, 255, 255, 0.50);
    border-width: 1px;
    padding: 5px 12px 5px 12px;
    display: flex;
    flex-direction: row;
    gap: 171px;
    align-items: center;
    justify-content: flex-start;
    flex-shrink: 0;
    height: 41px;
    position: relative;
    overflow: hidden;
}

.password {
    color: rgba(255, 255, 255, 0.49);
    text-align: left;
    font-family: 'Montserrat-Regular', sans-serif;
    font-size: 16px;
    line-height: 31px;
    font-weight: 400;
    position: relative;
    align-self: stretch;
    flex: 1;
    display: flex;
    align-items: center;
    justify-content: flex-start;
}

.vector {
    flex-shrink: 0;
    width: 22px;
    height: 15px;
    position: relative;
    overflow: visible;
}
.forgot-password,
.forgot-password * {
    box-sizing: border-box;
}

.forgot-password {
    color: rgba(255, 255, 255, 0.49);
    text-align: left;
    font-family: '-', sans-serif;
    font-size: 14px;
    line-height: 31px;
    font-weight: 400;
    position: relative;
    align-self: stretch;
    flex: 1;
    display: flex;
    align-items: center;
    justify-content: flex-start;
}

.forgot-password-span {
    color: rgba(255, 255, 255, 0.49);
    font-family: 'Montserrat-Medium', sans-serif;
    font-size: 14px;
    line-height: 31px;
    font-weight: 500;
}

.forgot-password-span2 {
    color: rgba(255, 255, 255, 0.49);
    font-family: 'Montserrat-Light', sans-serif;
    font-size: 14px;
    line-height: 31px;
    font-weight: 300;
}
.click-here,
.click-here * {
    box-sizing: border-box;
}

.click-here {
    color: rgba(255, 255, 255, 0.49);
    text-align: left;
    font-family: 'Montserrat-Light', sans-serif;
    font-size: 14px;
    line-height: 31px;
    font-weight: 300;
    text-decoration: underline;
    position: relative;
    align-self: stretch;
    flex: 1;
    display: flex;
    align-items: center;
    justify-content: flex-start;
}
.frame-1000002772,
.frame-1000002772 * {
    box-sizing: border-box;
}

.frame-1000002772 {
    background: #d9d9d9;
    border-radius: 10px;
    border-style: solid;
    border-color: rgba(255, 255, 255, 0.50);
    border-width: 1px;
    padding: 5px 156.25px 5px 156.25px;
    display: flex;
    flex-direction: row;
    gap: 10px;
    align-items: center;
    justify-content: center;
    height: 41px;
    position: relative;
    overflow: hidden;
}

.sign-up {
    color: #000000;
    text-align: center;
    font-family: 'Montserrat-Regular', sans-serif;
    font-size: 16px;
    line-height: 31px;
    font-weight: 400;
    position: relative;
    align-self: stretch;
    flex: 1;
    display: flex;
    align-items: center;
    justify-content: center;
}
.sign-up,
.sign-up * {
    box-sizing: border-box;
}

.sign-up {
    color: #000000;
    text-align: center;
    font-family: 'Montserrat-Regular', sans-serif;
    font-size: 16px;
    line-height: 31px;
    font-weight: 400;
    position: relative;
    align-self: stretch;
    flex: 1;
    display: flex;
    align-items: center;
    justify-content: center;
}
.line-1,
.line-1 * {
    box-sizing: border-box;
}

.line-1 {
    margin-top: -1px;
    border-style: solid;
    border-color: #ffffff;
    border-width: 1px 0 0 0;
    width: 164.61px;
    height: 0px;
    position: relative;
}
.or,
.or * {
    box-sizing: border-box;
}

.or {
    color: rgba(255, 255, 255, 0.49);
    text-align: center;
    font-family: 'Lato-Regular', sans-serif;
    font-size: 16px;
    line-height: 31px;
    font-weight: 400;
    position: relative;
    width: 160.36px;
    display: flex;
    align-items: center;
    justify-content: center;
}
.line-2,
.line-2 * {
    box-sizing: border-box;
}

.line-2 {
    margin-top: -1px;
    border-style: solid;
    border-color: #ffffff;
    border-width: 1px 0 0 0;
    width: 164.61px;
    height: 0px;
    position: relative;
}
.frame-1000002773,
.frame-1000002773 * {
    box-sizing: border-box;
}

.frame-1000002773 {
    background: #d9d9d9;
    border-radius: 10px;
    border-style: solid;
    border-color: rgba(255, 255, 255, 0.50);
    border-width: 1px;
    padding: 5px 80px 5px 80px;
    display: flex;
    flex-direction: row;
    gap: 25px;
    align-items: center;
    justify-content: flex-start;
    flex-shrink: 0;
    height: 41px;
    position: relative;
    overflow: hidden;
}

.google {
    flex-shrink: 0;
    width: 21px;
    height: 21px;
    position: relative;
    overflow: visible;
}

.sign-up-with-google {
    color: #000000;
    text-align: left;
    font-family: 'Montserrat-Regular', sans-serif;
    font-size: 16px;
    line-height: 31px;
    font-weight: 400;
    position: relative;
    align-self: stretch;
    flex: 1;
    display: flex;
    align-items: center;
    justify-content: flex-start;
}

.sign-up-with-google-span {
    color: #000000;
    font-family: 'Montserrat-Regular', sans-serif;
    font-size: 16px;
    line-height: 31px;
    font-weight: 400;
}

.sign-up-with-google-span2 {
    color: #000000;
    font-family: 'Montserrat-Regular', sans-serif;
    font-size: 16px;
    line-height: 31px;
    font-weight: 400;
}

.sign-up-with-google-span3 {
    color: #000000;
    font-family: 'Montserrat-Regular', sans-serif;
    font-size: 16px;
    line-height: 31px;
    font-weight: 400;
}
.sign-up-with-google,
.sign-up-with-google * {
    box-sizing: border-box;
}

.sign-up-with-google {
    color: #000000;
    text-align: left;
    font-family: 'Montserrat-Regular', sans-serif;
    font-size: 16px;
    line-height: 31px;
    font-weight: 400;
    position: relative;
    align-self: stretch;
    flex: 1;
    display: flex;
    align-items: center;
    justify-content: flex-start;
}

.sign-up-with-google-span {
    color: #000000;
    font-family: 'Montserrat-Regular', sans-serif;
    font-size: 16px;
    line-height: 31px;
    font-weight: 400;
}

.sign-up-with-google-span2 {
    color: #000000;
    font-family: 'Montserrat-Regular', sans-serif;
    font-size: 16px;
    line-height: 31px;
    font-weight: 400;
}

.sign-up-with-google-span3 {
    color: #000000;
    font-family: 'Montserrat-Regular', sans-serif;
    font-size: 16px;
    line-height: 31px;
    font-weight: 400;
}
.already-have-an-account,
.already-have-an-account * {
    box-sizing: border-box;
}

.already-have-an-account {
    color: rgba(255, 255, 255, 0.49);
    text-align: center;
    font-family: '-', sans-serif;
    font-size: 14px;
    line-height: 31px;
    font-weight: 400;
    position: relative;
    display: flex;
    align-items: center;
    justify-content: center;
}

.already-have-an-account-span {
    color: rgba(255, 255, 255, 0.49);
    font-family: 'Montserrat-SemiBold', sans-serif;
    font-size: 14px;
    line-height: 31px;
    font-weight: 600;
}

.already-have-an-account-span2 {
    color: rgba(255, 255, 255, 0.49);
    font-family: 'Montserrat-Regular', sans-serif;
    font-size: 14px;
    line-height: 31px;
    font-weight: 400;
}
.log-in,
.log-in * {
    box-sizing: border-box;
}

.log-in {
    color: rgba(255, 255, 255, 0.49);
    text-align: center;
    font-family: 'Montserrat-Regular', sans-serif;
    font-size: 14px;
    line-height: 31px;
    font-weight: 400;
    text-decoration: underline;
    position: relative;
    display: flex;
    align-items: center;
    justify-content: center;
}
.rectangle-3,
.rectangle-3 * {
    box-sizing: border-box;
}

.rectangle-3 {
    background: #000000;
    flex-shrink: 0;
    height: 800px;
    position: relative;
}
.rectangle-2,
.rectangle-2 * {
    box-sizing: border-box;
}

.rectangle-2 {
    flex-shrink: 0;
    height: 800px;
    position: relative;
}
.textlight {
  color: #D3D9D5;
text-align: center;
font-family:  sans-serif,'Montserrat';
font-size: 16px;
font-style: normal;
font-weight: 300;
line-height: normal;
}
.textdark {
  color: #D3D9D5;
  font-family:  sans-serif,'Montserrat';
  font-size: 16px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
}
.EmailAddress
{
  display: flex;
  width: 378px;
  height: 41px;
  padding: 5px 171px 5px 12px;
  justify-content: center;
  align-items: center;
  gap: 10px;
  border-radius: 10px;
  border: 1px solid rgba(255, 255, 255, 0.50);
}
.Password
{
  display: flex;
width: 377px;
height: 41px;
padding: 5px 12px;
align-items: center;
gap: 171px;
border-radius: 10px;
border: 1px solid rgba(255, 255, 255, 0.50);
}
.Frame1000002770
{
  display: flex;
  width: 377px;
  height: 41px;
  padding: 5px 12px;
  align-items: center;
  gap: 171px;
  border-radius: 10px;
border: 1px solid rgba(255, 255, 255, 0.50);
}
.Frame1000002772
{
  display: flex;
  width: 377px;
  height: 41px;
  padding: 5px 12px;
  align-items: center;
  gap: 171px;
  border-radius: 10px;
border: 1px solid rgba(255, 255, 255, 0.50);
}
.SignUptext
{
  display: flex;
flex-direction: column;
justify-content: center;
flex: 1 0 0;
align-self: stretch;
width: 100%;
}
.imgstyle
{
  display: flex;
height: 115px;
justify-content: center;
align-items: center;
flex-shrink: 0;
align-self: stretch;
}
.Frame1000002772
{
  display: flex;
  width: 336px;
  height: 243px;
  flex-direction: column;
  align-items: center;
  gap: 9px;
}
.emailctrl
{
  color: rgba(255, 255, 255, 0.49);
font-family:  sans-serif,'Montserrat';
font-size: 16px !important;
font-style: normal;
font-weight: 400;
line-height: 31px; /* 193.75% */
display: flex;
width: 378px;
height: 41px;
background: #000000;
padding: 5px 12px 5px 12px !important;
justify-content: center;
align-items: center;
gap: 10px;
border-radius: 10px;
border: 1px solid rgba(255, 255, 255, 0.50);
}
.signinbtn
{
  display: flex;
  width: 376.5px;
  height: 41px;
  padding: 5px 156.25px;
  justify-content: center;
  align-items: center;
  gap: 10px;
  flex-shrink: 0;
  border-radius: 10px;
border: 1px solid rgba(255, 255, 255, 0.50);
background: #D9D9D9;
}
.Line1
{
  width: 164.606px;
height: 1px;
background: #FFF;
}
.or{

  width: 377px;
height: 31px;
position: absolute;
right: 171.5px;
bottom: 241.452px;
}
.line-2{
  width: 164.606px;
height: 1px;
background: #FFF;
}
.googlecss
{
  font-family:  sans-serif,'Montserrat';
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: 31px;
    text-transform: capitalize;
    display: flex;
    width: 378px;  
    background: #ffffff;
    justify-content: center;
    align-items: center;
    border-radius: 10px;
    border: 1px solid rgba(255, 255, 255, 0.50);
    margin-bottom: 0px !important;
    border-top-width: 0px;
    border-bottom-width: 0px;
    display: flex;
    width: 376.5px;
    height: 41px;
    padding: 5px 156.25px;
    justify-content: center;
    align-items: center;
    gap: 10px;
    flex-shrink: 0;
    border-radius: 10px;
    border: 1px solid rgba(255, 255, 255, 0.50);
    background: #D9D9D9;
}
.nsm7Bb-HzV7m-LgbsSe .nsm7Bb-HzV7m-LgbsSe-MJoBVe {
  font-family:  sans-serif,'Montserrat';
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 31px;
  text-transform: capitalize;
  display: flex;
  width: 378px;  
  
  .justify-content: center;
  align-items: center;
  border-radius: 10px;
  margin-bottom: 0px !important;
  border-top-width: 0px;
  border-bottom-width: 0px;
  display: flex;
  width: 376.5px;
  padding: 5px 156.25px;
  justify-content: center;
  align-items: center;
  gap: 10px;
  flex-shrink: 0;
  border-radius: 10px;
  height: 20px !important;
}

.text-fp
{
  color: rgba(255, 255, 255, 0.49); font-size: 14px !important; font-family:  sans-serif,'Montserrat'; font-weight: 300; text-transform: capitalize; line-height: 31px; word-wrap: break-word;
     position: relative;
     left: -80px;


}
.textlink-fp
{
width: 138px; height: 31px; color: #ffffff; font-size: 14px; font-family:  sans-serif,'Montserrat'; font-weight: 300; text-decoration: underline; line-height: 31px; word-wrap: break-word;
}

.text-login
{
color: rgba(255, 255, 255, 0.49); font-size: 14px; font-family:  sans-serif,'Montserrat'; font-weight: 600; line-height: 31px; word-wrap: break-word;
}
.textlink-login
{
width: 60px; height: 31px; color: rgba(255, 255, 255, 0.49); font-size: 14px; font-family:  sans-serif,'Montserrat'; font-weight: 300; text-decoration: underline; line-height: 31px; word-wrap: break-word;
}

.pwresetframe
{
  width: 720px; height: 800px; background: rgba(3, 4, 7, 0.80); border-top-left-radius: 20px; border-top-right-radius: 20px;
}
.ForgotPassword
{
  width: 100%; height: 100%; position: relative; background: black;

}
.StockWiseAnalyticsLogo
{
  width: 336.02px; height: 115px; left: 914px; top: 55px; position: absolute; justify-content: center; align-items: center; display: inline-flex;
}
.Rectangle2
{
  width: 720px; height: 800px; left: 0px; top: 0px; position: absolute; border-top-left-radius: 20px; border-top-right-radius:20px ;
}

.forgot-password,
.forgot-password * {
    box-sizing: border-box;
}

.forgot-password {
    background: #000000;
    height: 800px;
    position: relative;
    overflow: hidden;
}

.stock-wise-analytics-logo {
    width: 336.02px;
    height: 115px;
    position: absolute;
    left: 914px;
    top: 55px;
}

.logo-05-1 {
    width: 100%;
    height: 100%;
    position: absolute;
    right: 0%;
    left: 0%;
    bottom: 0%;
    top: 0%;
    object-fit: cover;
}

.rectangle-2 {
    border-radius: 0px 20px 20px 0px;
    width: 720px;
    height: 800px;
    position: absolute;
    left: calc(50% - 720px);
    top: 0px;
    object-fit: cover;
}

.rectangle-3 {
    background: rgba(3, 4, 7, 0.80);
    border-radius: 0px 20px 20px 0px;
    width: 720px;
    height: 800px;
    position: absolute;
    left: calc(50% - 720px);
    top: 0px;
}

.forgot-password2 {
    color: #d3d9d5;
    text-align: center;
    font-family: 'Montserrat-Bold', sans-serif;
    font-size: 32px;
    font-weight: 700;
    position: absolute;
    right: 14.1%;
    left: 64.38%;
    width: 21.53%;
    bottom: 70.62%;
    top: 26.88%;
    height: 2.5%;
    display: flex;
    align-items: center;
    justify-content: center;
}

.enter-your-email-address-and-we-ll-send-you-a-link-to-reset-your-password {
    color: #d3d9d5;
    text-align: center;
    font-family: '-', sans-serif;
    font-size: 16px;
    font-weight: 400;
    position: absolute;
    right: 9.72%;
    left: 60%;
    width: 30.28%;
    bottom: 47.5%;
    top: 46%;
    height: 6.5%;
    display: flex;
    align-items: center;
    justify-content: center;
}

.enter-your-email-address-and-we-ll-send-you-a-link-to-reset-your-password-span {
    color: #d3d9d5;
    font-family: 'Montserrat-Light', sans-serif;
    font-size: 16px;
    font-weight: 300;
}

.enter-your-email-address-and-we-ll-send-you-a-link-to-reset-your-password-span2 {
    color: #d3d9d5;
    font-family: 'Montserrat-Bold', sans-serif;
    font-size: 16px;
    font-weight: 700;
}

.enter-your-email-address-and-we-ll-send-you-a-link-to-reset-your-password-span3 {
    color: #d3d9d5;
    font-family: 'Montserrat-Light', sans-serif;
    font-size: 16px;
    font-weight: 300;
}

.email-and-password {
    display: flex;
    flex-direction: column;
    gap: 100px;
    align-items: flex-start;
    justify-content: flex-start;
    position: absolute;
    left: 905px;
    top: 434px;
}

.rectangle-25 {
    border-radius: 10px;
    border-style: solid;
    border-color: rgba(255, 255, 255, 0.50);
    border-width: 1px;
    flex-shrink: 0;
    width: 355px;
    height: 42px;
    position: relative;
}

.sign-up {
    flex-shrink: 0;
    width: 355px;
    height: 42px;
    position: static;
}

.rectangle-26 {
    background: #d9d9d9;
    border-radius: 10px;
    border-style: solid;
    border-color: rgba(255, 255, 255, 0.50);
    border-width: 1px;
    width: 355px;
    height: 42px;
    position: absolute;
    left: calc(50% - 177.5px);
    top: 142px;
}

.send-email {
    color: #000000;
    text-align: center;
    font-family: 'Montserrat-Regular', sans-serif;
    font-size: 16px;
    line-height: 31px;
    font-weight: 400;
    position: absolute;
    left: calc(50% - 75.5px);
    top: 148px;
    width: 151px;
    display: flex;
    align-items: center;
    justify-content: center;
}

.email-address {
    color: rgba(255, 255, 255, 0.49);
    text-align: left;
    font-family: 'Montserrat-Regular', sans-serif;
    font-size: 16px;
    line-height: 31px;
    font-weight: 400;
    position: absolute;
    left: calc(50% - 165.5px);
    top: 5px;
    width: 151px;
    display: flex;
    align-items: center;
    justify-content: flex-start;
}

.sign-up2 {
    position: absolute;
    inset: 0;
}

.rectangle-262 {
    background: #d9d9d9;
    border-radius: 10px;
    border-style: solid;
    border-color: rgba(255, 255, 255, 0.50);
    border-width: 1px;
    width: 355px;
    height: 42px;
    position: absolute;
    left: calc(50% - -185px);
    top: 642px;
}

.back {
    color: #000000;
    text-align: center;
    font-family: 'Montserrat-Regular', sans-serif;
    font-size: 16px;
    line-height: 31px;
    font-weight: 400;
    position: absolute;
    left: calc(50% - -287px);
    top: 648px;
    width: 151px;
    display: flex;
    align-items: center;
    justify-content: center;
}

.vector {
    width: 37px;
    height: 37px;
    position: absolute;
    left: 1410px;
    top: 30px;
    transform: translate(-37px, 0px);
    ;
    overflow: visible;
}


.pftext{
  width: 310px; height: 20px; text-align: center; color: #D3D9D5; font-size: 16px; font-family:  sans-serif,'Montserrat';line-height: 31px; font-weight: 700; word-wrap: break-word;
}

.SendEmailtext
{
  width: 310px; text-align: center; color: black; font-size: 16px; font-family:  sans-serif,'Montserrat'; font-weight: 400; text-transform: capitalize; line-height: 31px; word-wrap: break-word;
}
.backtext{
  width: 310px; text-align: center; color: black; font-size: 16px; font-family:  sans-serif,'Montserrat'; font-weight: 400; text-transform: capitalize; line-height: 31px; word-wrap: break-word;
}
.fptitle
{
  width: 310px; height: 20px; text-align: center; 
  color: #D3D9D5; 
  font-size: 32px; font-family:  sans-serif,'Montserrat'; font-weight: 700; word-wrap: break-word;
}

.well
      {
         padding: 35px;
         padding-left: 30px;
         box-shadow: 0 0 50px #666666;
         margin: 7% auto 0;
         width: 450px;
         background: rgba(0.4, 0, 0, 0.4);
      }

      .btn1
      {
         background-color: #FF3838;
         color: white;
         transition: all 0.5s;
      }
      .btn1:hover, .btn1:focus
      {
         background-color: white;
         color: black;
         border: 1px solid;
         border-color: #FF3838;
         transition: 0.5s;
      }
      a:hover
      {
         text-decoration: none;
         color: #c0c0c0;
      }

      body
      {
         background: url('http://cdn.pcwallart.com/images/nature-wallpapers-high-resolution-wallpaper-4.jpg');
      }

      .well-header
      {
         background-color: #FF3838;
      }

      .input-group-addon
      {
          background-color: #FF3838;
          border-color: #FF3838;
          color: white;
      }

      .btn2
      {
         transition: all 0.5s;
      }


      .btn2:hover, .btn2:focus
      {
         border-color: #428bca;
         color: black;
         background-color: white;
         transition: 0.5s;
      }

      .password-wrapper {
        position: relative;
        text-align: center;
      }
      
      .toggle-button {
        display: inline-flex;
        position: absolute;
        top: 50%;
        transform: translateY(-50%);
        left: unset;
        right: 12px;
        cursor: pointer;
      }
      
      .eye-icon {
        width: 20px;
        height: 20px;
      }
      .csstemp{
        position: relative;
        display: block;
        margin : 0 auto;
      }
      .inputpw_box
      {
        margin: 0 auto;
        text-align: left;
      }
      .nsm7Bb-HzV7m-LgbsSe .nsm7Bb-HzV7m-LgbsSe-BPrWId {
        font-family:  sans-serif,'Montserrat' !important;
      }
      .nsm7Bb-HzV7m-LgbsSe {
        background: #D9D9D9 !important;
        font-size: 16px !important;
      }
      .poscss
{
  z-index: 2; 
  position: relative;
  cursor: pointer;
  padding: 4px;
  margin: 0;
  display: inline-flex;
  align-items: center;
  justify-content: center;  
}
.fpw_heading
{
  height: 20px; text-align: center; color: #D3D9D5; font-size: 32px; font-family:  sans-serif,'Montserrat'; font-weight: 700; word-wrap: break-word;
}
.fpw_text1
{
  color: #D3D9D5; font-size: 16px; font-family:  sans-serif,'Montserrat'; font-weight: 300; word-wrap: break-word;
}
.fpw_text2
{
  color: #D3D9D5; font-size: 16px; font-family:  sans-serif,'Montserrat'; font-weight: 700; word-wrap: break-word;
}
.send_btn
{
  width: 151px;
   text-align: center; 
  color: black; 
  font-size: 16px; 
  font-family:  sans-serif,'Montserrat';
   font-weight: 400;
    text-transform: capitalize;
     line-height: 31px; 
       display: flex;
     width: 376.5px;
     height: 31px;
     padding: 5px 156.25px;
     justify-content: center;
     align-items: center;
     gap: 10px;
     flex-shrink: 0;
     border-radius: 10px;
   border: 1px solid rgba(255, 255, 255, 0.50);
   background: #D9D9D9;
}
.img-fluid {
  width: 100px; height: 68px;
}
.img-main-fluid {
  width: 200px; height: 68px; justify-content: center; align-items: center; display: inline-flex;
}
.scrollable-content {
  box-shadow: hsl(0 0% 0% / 0.25) 0 0.25em 0.5em;
  background: hsl(0 0% 100%);
  padding: 2em;
  margin-left: auto;
  margin-right: auto;
  max-width: 450px;
  max-height: 350px;
  overflow-y: scroll;
}

.line {
  display: inline-block;
  width: 23%;
  height: 1px;
  background-color: #ffffff;
  vertical-align: middle;
}
.or-text
{
text-align: center; color: rgba(255, 255, 255, 0.49); font-size: 16px; font-family:  sans-serif,'Montserrat'; font-weight: 400; text-transform: capitalize; line-height: 31px; word-wrap: break-word;
}
.align-items-center {
  align-items: center !important;
  display: flex;
}
.font-family-sans-serif {
  font-family:sans-serif,'Montserrat' !important;
}
input[type="text"] {
  cursor: pointer ! Important;
  margin: 0;
  padding: 0.2rem !important;
  font-family: sans-serif, "Montserrat" !important;
}