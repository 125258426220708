.MuiTablePagination-actions
{
    margin-bottom: 16px;
}
.css-187cpd0-MuiInputBase-root-MuiTablePagination-select
{
    margin-bottom: 16px;
}

.cardtitle{
    color: #000;
    text-align: center;
    font-family:  sans-serif,'Montserrat';
    font-size: 20px;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
}
.cardtitlevalue{
color: #000;
text-align: center;
font-family:  sans-serif,'Montserrat';
font-size: 30px;
font-style: normal;
font-weight: 300;
line-height: normal;
}
.lkr-0 {
    color: #000;
    text-align: center;
    font-family:  sans-serif,'Montserrat';
    font-size: 30px;
    font-style: normal;
    font-weight: 300;
    line-height: normal;
    display: flex;
    height: 36px;
    flex-direction: column;
    justify-content: center;
    flex-shrink: 0;
}

  .uldiv {
    color: #000;
  text-align: center;
  font-family: sans-serif,"Montserrat",;
  font-size: 16px;
  font-weight: 400;
  display: flex;
  align-items: center;
  justify-content: center;
}
.uldiv-span
{
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    flex-shrink: 0;
    color: #000;

font-family:  sans-serif,'Montserrat';
font-size: 16px;
font-style: normal;
font-weight: 400;
line-height: normal; 
}
.free2
{
    display: flex;
    flex-direction: column;
    justify-content: center;
    flex-shrink: 0;
    color: #000;

text-align: center;
font-family:  sans-serif,'Montserrat';
font-size: 20px;
font-style: normal;
font-weight: 700;
line-height: normal; 
}
/* .subscription-management {
  background: #000000;
  height: 1275px;
  position: relative;
  overflow: hidden;
}
.subscription-management .section-2 {
  background: rgba(255, 255, 255, 0.02);
  width: 1438px;
  height: 621px;
  position: absolute;
  left: 1px;
  top: 446px;
}
.subscription-management .free {
  position: absolute;
  inset: 0;
}
.subscription-management .rectangle-199 {
  background: rgba(255, 255, 255, 0.6);
  border-radius: 20px;
  width: 349px;
  height: 445px;
  position: absolute;
  left: 106px;
  top: 88px;
}
.subscription-management .free2 {
    color: #ffffff;
  text-align: center;
  font-family: sans-serif, "Montserrat-Bold";
  font-size: 20px;
  font-weight: 700;
  position: absolute;
  left: 233px;
  top: 144px;
  width: 96px;
  height: 19px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.subscription-management .rectangle-25 {
  background: #000000;
  border-radius: 5px;
  width: 143px;
  height: 36px;
  position: absolute;
  left: 209px;
  top: 441px;
}
.subscription-management .existing-plan {
  color: rgba(255, 255, 255, 0.8);
  text-align: center;
  font-family: sans-serif,"Montserrat",;
  font-size: 16px;
  font-weight: 400;
  position: absolute;
  left: 215px;
  top: 441px;
  width: 132px;
  height: 37px;
  display: flex;
  align-items: center;
  justify-content: center;
}
.subscription-management .premium {
  position: absolute;
  inset: 0;
}
.subscription-management .rectangle-1992 {
  background: #ffffff;
  border-radius: 20px;
  width: 349px;
  height: 445px;
  position: absolute;
  left: 544px;
  top: 88px;
}
.subscription-management .premium2 {
    color: #ffffff;
  text-align: center;
  font-family: sans-serif, "Montserrat-Bold";
  font-size: 20px;
  font-weight: 700;
  position: absolute;
  left: 648px;
  top: 144px;
  width: 142px;
  height: 19px;
  display: flex;
  align-items: center;
  justify-content: center;
}
.subscription-management .lkr-8-000-month {
  color: #ffffff;
  text-align: center;
  position: absolute;
  left: 570px;
  top: 204px;
  width: 298px;
  height: 36px;
  display: flex;
  align-items: center;
  justify-content: center;
}
.lkr-8-000-month-span {
  color: #ffffff;
  font-family:  sans-serif,"Montserrat-Light";
  font-size: 30px;
  font-weight: 300;
}
.lkr-8-000-month-span2 {
  color: #ffffff;
  font-family:  sans-serif,"Montserrat-Light";
  font-size: 20px;
  font-weight: 300;
}
.subscription-management
  .uldiv2 {
  color: #ffffff;
  text-align: left;
  font-family: sans-serif,"Montserrat",;
  font-size: 16px;
  font-weight: 400;
  position: absolute;
  left: 595px;
  top: 274px;
  width: 248px;
  height: 36px;
  display: flex;
  align-items: center;
  justify-content: flex-start;
}
.subscription-management .rectangle-252 {
  background: #000000;
  border-radius: 5px;
  width: 143px;
  height: 36px;
  position: absolute;
  left: 647px;
  top: 441px;
}
.subscription-management .try-it-now {
  color: #ffffff;
  text-align: center;
  font-family: sans-serif,"Montserrat",;
  font-size: 16px;
  font-weight: 400;
  position: absolute;
  left: 653px;
  top: 441px;
  width: 132px;
  height: 37px;
  display: flex;
  align-items: center;
  justify-content: center;
}
.subscription-management .rectangle-1993 {
  background: rgba(255, 255, 255, 0.6);
  border-radius: 20px;
  width: 349px;
  height: 445px;
  position: absolute;
  left: 982px;
  top: 88px;
}
.subscription-management .standard {
  color: #ffffff;
  text-align: center;
  font-family: sans-serif, "Montserrat-Bold";
  font-size: 20px;
  font-weight: 700;
  position: absolute;
  left: 1086px;
  top: 144px;
  width: 142px;
  height: 19px;
  display: flex;
  align-items: center;
  justify-content: center;
}
.subscription-management .lkr-5-000-month {
  color: #ffffff;
  text-align: center;
  position: absolute;
  left: 1008px;
  top: 204px;
  width: 298px;
  height: 36px;
  display: flex;
  align-items: center;
  justify-content: center;
}
.lkr-5-000-month-span {
    color: #ffffff;
  font-family:  sans-serif,"Montserrat-Light";
  font-size: 30px;
  font-weight: 300;
}
.lkr-5-000-month-span2 {
  color: #ffffff;
  font-family:  sans-serif,"Montserrat-Light";
  font-size: 20px;
  font-weight: 300;
}
.subscription-management
  .uldiv3 {
  color: #ffffff;
  text-align: left;
  font-family: sans-serif,"Montserrat",;
  font-size: 16px;
  font-weight: 400;
  position: absolute;
  left: 1033px;
  top: 274px;
  width: 248px;
  height: 36px;
  display: flex;
  align-items: center;
  justify-content: flex-start;
}
.subscription-management .rectangle-253 {
  background: #000000;
  border-radius: 5px;
  width: 143px;
  height: 36px;
  position: absolute;
  left: 1085px;
  top: 441px;
}
.subscription-management .try-it-now2 {
  color: rgba(255, 255, 255, 0.6);
  text-align: center;
  font-family: sans-serif,"Montserrat",;
  font-size: 16px;
  font-weight: 400;
  position: absolute;
  left: 1091px;
  top: 441px;
  width: 132px;
  height: 37px;
  display: flex;
  align-items: center;
  justify-content: center;
}
.subscription-management .section-1 {
  position: absolute;
  inset: 0;
}
.subscription-management .rectangle-2 {
  width: 1440px;
  height: 446px;
  position: absolute;
  left: calc(50% - 720px);
  top: 0px;
  object-fit: cover;
}
.subscription-management .rectangle-3 {
  background: rgba(3, 4, 7, 0.8);
  width: 1440px;
  height: 446px;
  position: absolute;
  left: calc(50% - 720px);
  top: 0px;
}
.subscription-management :global(.profile-details-instance) {
  width: 327px !important;
  position: absolute !important;
  left: 26px !important;
  top: 158px !important;
}
.subscription-management .group-12 {
  width: 575px;
  height: 53px;
  position: static;
}
.subscription-management .subscription-management2 {
  color: #d3d9d5;
  text-align: center;
  font-family: sans-serif, "Montserrat-Bold";
  font-size: 40px;
  font-weight: 700;
  position: absolute;
  left: 432px;
  top: 227px;
  width: 575px;
  height: 53px;
  display: flex;
  align-items: center;
  justify-content: center;
}
.subscription-management :global(.header-after-login-instance) {
  width: 100% !important;
  height: 6.98% !important;
  position: absolute !important;
  right: 0% !important;
  left: 0% !important;
  bottom: 93.02% !important;
  top: 0% !important;
}
.subscription-management
  .upgrade-your-subscribed-package-and-experience-premium-features {
  color: #d3d9d5;
  text-align: center;
  font-family: "-", sans-serif;
  font-size: 16px;
  font-weight: 400;
  position: absolute;
  left: 430px;
  top: 290px;
  width: 579px;
  height: 83px;
  display: flex;
  align-items: center;
  justify-content: center;
}
.upgrade-your-subscribed-package-and-experience-premium-features-span {
  color: #d3d9d5;
  font-family: sans-serif, "Montserrat-Bold";
  font-size: 16px;
  font-weight: 700;
}
.upgrade-your-subscribed-package-and-experience-premium-features-span2 {
  color: #d3d9d5;
  font-family: sans-serif,"Montserrat",;
  font-size: 16px;
  font-weight: 400;
}
.upgrade-your-subscribed-package-and-experience-premium-features-span3 {
  color: #d3d9d5;
  font-family: sans-serif, "Montserrat-Bold";
  font-size: 16px;
  font-weight: 700;
}
.subscription-management :global(.footer-instance) {
  width: 1440px !important;
  position: absolute !important;
  left: 0px !important;
  top: 1067px !important;
} */
.card {
    border: none;
    border-radius: 10px
}

.c-details span {
    font-weight: 300;
    font-size: 13px
}

.icon {
    width: 50px;
    height: 50px;
    background-color: #eee;
    border-radius: 15px;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 39px
}

.badge span {
    background-color: #fffbec;
    width: 60px;
    height: 25px;
    padding-bottom: 3px;
    border-radius: 5px;
    display: flex;
    color: #fed85d;
    justify-content: center;
    align-items: center
}

.progress {
    height: 10px;
    border-radius: 10px
}

.progress div {
    background-color: red
}

.text1 {
    font-size: 14px;
    font-weight: 600
}

.text2 {
    color: #a5aec0
}
/* .existing-plan {
  color: #fff;
  font-family: sans-serif,"Montserrat",;
  font-size: 16px;
  font-weight: 400;
  text-align: center;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 5px;
background: #000;
display: flex;
flex-direction: column;
flex-shrink: 0;
width: fit-content;
padding: 10px 10px;

} 
*/
.existing-plan {
padding: 10px 10px;
border-radius: 5px;
background-color: #000;
flex-wrap: nowrap;
}
.backcol{
  border-radius: 20px;
  background-color: rgba(255, 255, 255, 0.60);
}


