.forgot-password,
.forgot-password * {
  box-sizing: border-box;
}
.forgot-password {
  background: #000000;
  height: 800px;
  position: relative;
  overflow: hidden;
}
.stock-wise-analytics-logo {
  width: 336.02px;
  height: 115px;
  position: absolute;
  left: 914px;
  top: 55px;
}
.logo-05-1 {
  width: 100%;
  height: 100%;
  position: absolute;
  right: 0%;
  left: 0%;
  bottom: 0%;
  top: 0%;
  object-fit: cover;
}
.rectangle-2 {
  border-radius: 0px 20px 20px 0px;
  width: 720px;
  height: 800px;
  position: absolute;
  left: calc(50% - 720px);
  top: 0px;
  object-fit: cover;
}
.rectangle-3 {
  background: rgba(3, 4, 7, 0.8);
  border-radius: 0px 20px 20px 0px;
  width: 720px;
  height: 800px;
  position: absolute;
  left: calc(50% - 720px);
  top: 0px;
}
.forgot-password2 {
  color: #d3d9d5;
  text-align: center;
  font-family: sans-serif, "Montserrat-Bold";
  font-size: 32px;
  font-weight: 700;
  position: absolute;
  right: 14.1%;
  left: 64.38%;
  width: 21.53%;
  bottom: 70.62%;
  top: 26.88%;
  height: 2.5%;
  display: flex;
  align-items: center;
  justify-content: center;
}
.enter-your-email-address-and-we-ll-send-you-a-link-to-reset-your-password {
  color: #d3d9d5;
  text-align: center;
  font-family: "-", sans-serif;
  font-size: 16px;
  font-weight: 400;
  position: absolute;
  right: 9.72%;
  left: 60%;
  width: 30.28%;
  bottom: 47.5%;
  top: 46%;
  height: 6.5%;
  display: flex;
  align-items: center;
  justify-content: center;
}
.enter-your-email-address-and-we-ll-send-you-a-link-to-reset-your-password-span {
  color: #d3d9d5;
  font-family:  sans-serif,"Montserrat-Light";
  font-size: 16px;
  font-weight: 300;
}
.enter-your-email-address-and-we-ll-send-you-a-link-to-reset-your-password-span2 {
  color: #d3d9d5;
  font-family: sans-serif, "Montserrat-Bold";
  font-size: 16px;
  font-weight: 700;
}
.enter-your-email-address-and-we-ll-send-you-a-link-to-reset-your-password-span3 {
  color: #d3d9d5;
  font-family:  sans-serif,"Montserrat-Light";
  font-size: 16px;
  font-weight: 300;
}
.email-and-password {
  display: flex;
  flex-direction: column;
  gap: 100px;
  align-items: flex-start;
  justify-content: flex-start;
  position: absolute;
  left: 905px;
  top: 434px;
}
.rectangle-25 {
  border-radius: 10px;
  border-style: solid;
  border-color: rgba(255, 255, 255, 0.5);
  border-width: 1px;
  flex-shrink: 0;
  width: 355px;
  height: 42px;
  position: relative;
}
.sign-up {
  flex-shrink: 0;
  width: 355px;
  height: 42px;
  position: static;
}
.rectangle-26 {
  background: #d9d9d9;
  border-radius: 10px;
  border-style: solid;
  border-color: rgba(255, 255, 255, 0.5);
  border-width: 1px;
  width: 355px;
  height: 42px;
  position: absolute;
  left: calc(50% - 177.5px);
  top: 142px;
}
.send-email {
  color: #000000;
  text-align: center;
  font-family: sans-serif,"Montserrat",;
  font-size: 16px;
  line-height: 31px;
  font-weight: 400;
  position: absolute;
  left: calc(50% - 75.5px);
  top: 148px;
  width: 151px;
  display: flex;
  align-items: center;
  justify-content: center;
}
.email-address {
  color: rgba(255, 255, 255, 0.49);
  text-align: left;
  font-family: sans-serif,"Montserrat",;
  font-size: 16px;
  line-height: 31px;
  font-weight: 400;
  position: absolute;
  left: calc(50% - 165.5px);
  top: 5px;
  width: 151px;
  display: flex;
  align-items: center;
  justify-content: flex-start;
}
.sign-up2 {
  position: absolute;
  inset: 0;
}
.rectangle-262 {
  background: #d9d9d9;
  border-radius: 10px;
  border-style: solid;
  border-color: rgba(255, 255, 255, 0.5);
  border-width: 1px;
  width: 355px;
  height: 42px;
  position: absolute;
  left: calc(50% - -185px);
  top: 642px;
}
.back {
  color: #000000;
  text-align: center;
  font-family: sans-serif,"Montserrat",;
  font-size: 16px;
  line-height: 31px;
  font-weight: 400;
  position: absolute;
  left: calc(50% - -287px);
  top: 648px;
  width: 151px;
  display: flex;
  align-items: center;
  justify-content: center;
}
.bItem {
  /* display: flex;
  justify-content: flex-end;
  flex-direction: column;
  padding: 18px;
  background-size: cover;
  background-position: center center;
  background: linear-gradient(to bottom right, black, #282727) */
}
/* style={{ top: '20px', textAlign: 'center', width: '100%',background: 'linear-gradient(to bottom right, black, #282727)' }} */