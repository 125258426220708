.about-us-page,
.about-us-page * {
  box-sizing: border-box;
}

.about-us-page {
  background: #000000;
  height: 1135px;
  position: relative;
  overflow: hidden;
}
.about-us-page :global(.footer-instance) {
  width: 1440px !important;
  position: absolute !important;
  left: 0px !important;
  top: 927px !important;
}
.about-us-page .section-2 {
  position: absolute;
  inset: 0;
}
/* @media only screen and (max-width: 20rem) {
  .body {
    font-size: 0.5rem;
  }
} */

/* Small Devices, Phones (Up to 30rem) */
/* @media only screen and (min-width: 20rem) and (max-width: 30rem) {
  .body {
    font-size: 0.625rem;
  }
} */

/* Tablets and Small Devices (Up to 48rem) */
/* @media only screen and (min-width: 30rem) and (max-width: 48rem) {
  .body {
    font-size: 0.75rem;
  }
} */

/* Medium Devices and Tablets (Up to 62rem) */
/* @media only screen and (min-width: 48rem) and (max-width: 62rem) {
  .body {
    font-size: 0.875rem;
  }
} */

/* Large Devices, Desktops (Above 62rem) */
/* @media only screen and (min-width: 62rem) {
  .body {
    font-size: 1rem;
  }
} */

/* Large Devices, Large Desktops (min-width: 992px) */
/* @media (min-width: 992px) {
  .body {
    font-size: 1.125rem;
  }
} */
.about-us-page .rectangle-16 {
  background: rgba(255, 255, 255, 0.03);
  width: 1440px;
  height: 474px;
  position: absolute;
  left: 0px;
  top: 453px;
}
.about-us-page .unsplash-awxi-zmz-or-qo {
  width: 494.75px;
  height: 330px;
  position: absolute;
  left: 892px;
  top: 525px;
  object-fit: cover;
}
.about-us-page
  .about-text {
    font-size: 1.25rem;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    text-align: justify;
}
.about-us-page .section-1 {
  position: absolute;
  inset: 0;
}
.about-us-page .rectangle-2 {
  width: 1440px;
  height: 453px;
  position: absolute;
  left: calc(50% - 720px);
  top: 0px;
}
.about-us-page .rectangle-3 {
  background: rgba(3, 4, 7, 0.8);
  width: 1440px;
  height: 453px;
  position: absolute;
  left: calc(50% - 720px);
  top: 0px;
}
.about-us-page .about-us {
  color: #d3d9d5;
  text-align: center;
  font-family: sans-serif, "Montserrat-Bold";
  font-size: 50px;
  font-weight: 700;
  position: absolute;
  left: 591px;
  top: 227px;
  width: 259px;
  height: 59px;
  display: flex;
  align-items: center;
  justify-content: center;
}
.about-us-page :global(.header-instance) {
  width: 1440px !important;
  position: absolute !important;
  left: 0px !important;
  top: 0px !important;
}  
  
  .trading-platform,
.trading-platform * {
  box-sizing: border-box;
}

.trading-platform {
  color: #d3d9d5;
  text-align: justified;
  font-family: 'Montserrat-Regular', sans-serif;
  font-size: 20px;
  font-weight: 400;
  position: relative;
  height: 422px;
  display: flex;
  align-items: center;
}
.about-us {
    color: #d3d9d5;
    text-align: center;
    font-family: sans-serif, "Montserrat-Bold";
    font-size: 50px;
    font-weight: 700;
    position: absolute;
    left: 591px;
    top: 227px;
    width: 259px;
    height: 59px;
    display: flex;
    align-items: center;
    justify-content: center;
  }
  .aboutuscss
  {
    width: 259px; height: 59px; text-align: center; color: #D3D9D5; font-size: 50px; font-family:  sans-serif,'Montserrat'; font-weight: 700; word-wrap: break-word;
  }
  .img-fluid-au, .img-main-fluid-au {
    width:100%;
    max-width: 100%;
    height: auto;
    display: block;
    margin: 0 auto;
}
.overlay-text {
  position: absolute;
  background-color: #07000087;
  z-index: 10;
  width: 100%;
  height: 100%;
  overflow: hidden;
}
.overlay-text h1 {
  color: #D3D9D5; 
  font-size: 50px !important; 
  font-family:  sans-serif,'Montserrat'; 
  font-weight: 700;
  display: flex;
  align-items: center;
  justify-content: center;
  position: absolute;
  width: 80%;
  left: 50%;
  top: 55%;
  transform: translate(-50%,-50%);
}

.overlay-texttt-h1 {
  color: #D3D9D5; 
  font-size: 50px !important; 
  font-family:  sans-serif,'Montserrat'; 
  font-weight: 700;
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
  width: 80%;
  left: 50%;
  top: 55%;
  transform: translate(-50%,-50%);
}
.align-items-centerau {
  align-items: start !important;
  display: flex;
}
 /* Tablets */
 /* @media (max-width: 1024px) {
  .container-fluid {
    padding: 15px;
    font-size: 0.75rem;
    text-align: center !important;
    justify-content: center;
    display:    block;
  }
  .about-text{
    padding: 15px;
    font-size: 0.75rem;
    text-align: center !important;
    justify-content: center;
    display:    block;
  }
} */

/* Mobile */
/* @media (max-width: 768px) {
  .container-fluid {
    padding: 10px;
    font-size: 0.5rem;
    text-align: center !important;
    justify-content: center;
    display:    block;
  }
  .about-text{
    padding: 10px;
    font-size: 0.5rem;
    text-align: center !important;
    justify-content: center;
    display:    block;
  }
} */
.about-text {
color: #D3D9D5;
text-align: justify;
font-family:  sans-serif,'Montserrat'; 
font-size: 20px;
font-style: normal;
font-weight: 400;
line-height: normal;
word-wrap: break-word;
  
}
about-text-sml
{
  color: #D3D9D5;
  text-align: center;
  font-family:  sans-serif,'Montserrat'; 
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  word-wrap: break-word;
  display: flex;
    align-items: center;
    
  }

.divabout-text 
{
  display: flex;
flex-direction: column;
justify-content: center;
flex-shrink: 0;
}
.main-banner {
  position: relative;
}

#bg-video {
    min-width: 100vw;
    min-height: 57vh;
    max-width: 100%;
    max-height: 57vh;
    object-fit: cover;
    opacity:0.8;
    filter:brightness(80%);
    z-index: -1;
    overflow: hidden;
    padding: 0px !important;
}
/* position: absolute;
top: 0;
left: 0;
width: 100%;
height: 100%;
object-fit: cover;
opacity:0.08;
#bg-video::-webkit-media-controls {
    display: none !important;
} */

.video-overlay {
    position: absolute;
    background-color: rgba(35,45,57,0.8);
    top: 0;
    left: 0;
    bottom: 7px;
    width: 100%;
}

.main-banner .caption {
  color: #D3D9D5; 
  font-size: 50px !important; 
  font-family:  sans-serif,'Montserrat'; 
  text-align: center;
  position: absolute;
  width: 80%;
  left: 50%;
  top: 60%;
  transform: translate(-50%,-50%);
}

.main-banner .caption h6 {
  margin-top: 0px;
  font-size: 18px;
  text-transform: uppercase;
  font-weight: 800;
  color: #fff;
  letter-spacing: 0.5px;
}

.main-banner .caption h2 {
  margin-top: 30px;
  margin-bottom: 25px;
  font-size: 84px;
  text-transform: uppercase;
  font-weight: 800;
  color: #fff;
  letter-spacing: 1px;
}

.main-banner .caption h2 em {
  font-style: normal;
  color: #ed563b;
  font-weight: 900;
}
.browser {
  margin: 0 !important;
  padding: 0 !important;
  height: auto !important;
}

.rounded-full {
  border-radius: 9999px;
}

.cntr_css{
  border: none;
  flex-wrap: wrap;
  align-content: center !important;
}
.cntr_css1{
  border: none;
}
.font-family-sans-serif {
  font-family:sans-serif,'Montserrat' !important;
}
