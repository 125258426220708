.profile-settings,
.profile-settings * {
  box-sizing: border-box;
}
.profile-settings {
  background: #000000;
color: #fff;
  height: 89.9375rem;
  overflow: hidden;
}
.profile-settings2 {
  background: #000000;
  width: 90rem;
  height: 89.9375rem;
  overflow: hidden;
}
.section-1 {
  inset: 0;
}
.rectangle-2 {
  width: 90rem;
  height: 27.875rem;
  object-fit: cover;
}
.rectangle-3 {
  background: rgba(3, 4, 7, 0.8);
  width: 90rem;
  height: 27.875rem;
}
.profile-details {
  width: 20.4375rem;
  height: 10.25rem;
}
.cse-maverick {
  color: #d3d9d5;
  text-align: left;
  font-family: sans-serif,"Montserrat-SemiBold";
  font-size: 1.25rem;
  font-weight: 600;
  display: flex;
  align-items: center;
  justify-content: flex-start;
}
.rectangle-28 {
  border-radius: 0.3125rem;
  width: 33.64%;
  height: 67.07%;
  object-fit: cover;
}
.vector {
  width: 7.65%;
  height: 13.41%;
  overflow: visible;
}
.user-details {
  inset: 0;
}
.online {
  color: #d3d9d5;
  text-align: left;
  font-family: sans-serif,"Montserrat",;
  font-size: 0.75rem;
  font-weight: 400;
  width: 23.85%;
  height: 17.68%;
  display: flex;
  align-items: center;
  justify-content: flex-start;
}
.joined-2-years-ago {
  color: #d3d9d5;
  text-align: left;
  font-family: sans-serif,"Montserrat",;
  font-size: 0.75rem;
  font-weight: 400;
  width: 41.59%;
  height: 17.68%;
  display: flex;
  align-items: center;
  justify-content: flex-start;
}
.vector2 {
  width: 4.59%;
  height: 9.15%;
  overflow: visible;
}
.vector3 {
  width: 4.59%;
  height: 9.15%;
  overflow: visible;
}
.group-12 {
  width: 21.0625rem;
  height: 3.3125rem;
  position: static;
}
.profile-settings3 {
  color: #d3d9d5;
  font-family: sans-serif, "Montserrat-Bold";
  font-size: 2.5rem;
  font-weight: 700;
  width: 21.0625rem;
  height: 3.3125rem;
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;
}
.header-after-login {
  width: 100%;
  height: 6.18%;
}
.rectangle-12 {
  background: rgba(0, 0, 0, 0.8);
  width: 100%;
  height: 100%;
}
.logo-03-1 {
  width: 4.15%;
  height: 67.42%;
  object-fit: cover;
}
.about-us {
  color: #d3d9d5;
  text-align: center;
  font-family: sans-serif, "Montserrat-Bold";
  font-size: 3.125rem;
  font-weight: 700;
  margin: auto;
  top:40%;
  width: 16.1875rem;
  height: 3.6875rem;
}
.language {
  width: 3.75rem;
  height: 1.4375rem;
}
.language-group {
  position: absolute;
  inset: 0;
}
.en {
  color: #ffffff;
  text-align: center;
  font-family: sans-serif,"Montserrat",;
  font-size: 1rem;
  font-weight: 400;
  width: 1.75rem;
  height: 1.4375rem;
  display: flex;
  align-items: center;
  justify-content: center;
}
.globe-vector {
  width: 33.33%;
  height: 86.96%;
  overflow: visible;
}
.user-profile-dorpdown {
  width: 13.125rem;
  height: 17.625rem;

}
.group-2 {
  height: auto;
  overflow: visible;
}
.services-dropdown {
  width: 16.875rem;
  height: 10.0625rem;
 }
.services {
  width: 6.6875rem;
  height: 1.4375rem;
}
.services2 {
  color: #ffffff;
  text-align: center;
  font-family: sans-serif,"Montserrat",;
  font-size: 1rem;
  font-weight: 400;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
}
.vector4 {
  width: 9.35%;
  height: 21.74%;
  transform: translate(0rem, 0rem);
  overflow: visible;
}
.manage-your-account-details-and-preferences {
  color: #d3d9d5;
  text-align: center;
  font-family:  sans-serif,"Montserrat-Light";
  font-size: 1rem;
  font-weight: 300;
  width: 33.8125rem;
  height: 1.8125rem;
  display: flex;
  align-items: center;
  justify-content: center;
}
.footer {
  width: 90rem;
  height: 23.25rem;
}
.rectangle-122 {
  background: #000000;
  width: 100%;
  height: 100%;
}
.by-the-traders-for-the-traders {
  color: #d3d9d5;
  text-align: left;
  font-family: sans-serif,"Montserrat",;
  font-size: 0.8125rem;
  font-weight: 300;
  width: 12.01%;
  height: 10.22%;
  display: flex;
  align-items: center;
  justify-content: flex-start;
}
.stock-wise-analytics-logo {
  width: 12.17%;
  height: 16.13%;
 }
.logo-05-1 {
  width: 100%;
  height: 100%;
  object-fit: cover;
}
.twitter {
  height: auto;
  overflow: visible;
}
.english {
  color: #ffffff;
  text-align: center;
  font-family: sans-serif,"Montserrat",;
  font-size: 1rem;
  font-weight: 400;
  width: 3.54%;
  height: 6.18%;
  display: flex;
  align-items: center;
  justify-content: center;
}
.globe-vector2 {
  width: 1.39%;
  height: 5.38%;
  overflow: visible;
}
.vector5 {
  width: 0.69%;
  height: 1.34%;
  transform: translate(0rem, 0rem);
  overflow: visible;
}
.about-us2 {
  inset: 0;
}
.rectangle15 {
  background: rgba(255, 255, 255, 0.03);
  border-radius: 0.625rem;
  width: 12.68%;
  height: 12.37%;
}
.about-us3 {
  color: #ffffff;
  text-align: center;
  font-family: sans-serif,"Montserrat",;
  font-size: 1rem;
  font-weight: 400;
  width: 5.97%;
  height: 6.18%;
  display: flex;
  align-items: center;
  justify-content: center;
}
.contact-us {
  position: absolute;
  inset: 0;
}
.rectangle-152 {
  background: rgba(255, 255, 255, 0.03);
  border-radius: 0.625rem;
  width: 8.68%;
  height: 12.37%;
}
.contact-us2 {
  color: #ffffff;
  text-align: center;
  font-family: sans-serif,"Montserrat",;
  font-size: 1rem;
  font-weight: 400;
  width: 6.81%;
  height: 5.11%;
  display: flex;
  align-items: center;
  justify-content: center;
}
.for-business {
  position: absolute;
  inset: 0;
}
.rectangle-153 {
  background: rgba(255, 255, 255, 0.03);
  border-radius: 0.625rem;
  width: 9.93%;
  height: 12.37%;
  position: absolute;
}
.for-business2 {
  color: #ffffff;
  text-align: center;
  font-family: sans-serif,"Montserrat",;
  font-size: 1rem;
  font-weight: 400;
  width: 8.06%;
   height: 5.11%;
  display: flex;
  align-items: center;
  justify-content: center;
}
.image-11 {
  width: 37.36%;
  height: 18.55%;
   object-fit: cover;
}
.section-12 {
  width: 90rem;
  height: 38.8125rem;
}
.section-1-2 {
  background: rgba(255, 255, 255, 0.02);
  width: 45rem;
  height: 38.8125rem;
}
.private-details {
  color: #ffffff;
  text-align: left;
  font-family: sans-serif,"Montserrat-SemiBold";
  font-size: 1.125rem;
  font-weight: 600;
  width: 11.125rem;
  height: 2.6875rem;
  display: flex;
  align-items: center;
  justify-content: flex-start;
}
.note-this-information-will-not-be-displayed-in-public {
  color: #ffffff;
  text-align: left;
  font-family:  sans-serif,"Montserrat-Light";
  font-size: 0.875rem;
  font-weight: 300;
  width: 29.8125rem;
  height: 2.6875rem;
  display: flex;
  align-items: center;
  justify-content: flex-start;
}
.email {
  color: #ffffff;
  text-align: left;
  font-family: sans-serif,"Montserrat",;
  font-size: 1rem;
  font-weight: 400;
  width: 9.8125rem;
  height: 2.6875rem;
  display: flex;
  align-items: center;
  justify-content: flex-start;
}
.rectangle-154 {
  background: rgba(255, 255, 255, 0.1);
  border-radius: 0.3125rem;
  width: 8.0625rem;
  height: 2.25rem;
}
.change-email {
  color: #ffffff;
  text-align: center;
  font-family: sans-serif,"Montserrat",;
  font-size: 0.875rem;
  font-weight: 400;
  width: 6.1875rem;
  height: 1.4375rem;
  display: flex;
  align-items: center;
  justify-content: center;
}
.password {
  color: #ffffff;
  text-align: left;
  font-family: sans-serif,"Montserrat",;
  font-size: 1rem;
  font-weight: 400;
  width: 9.8125rem;
  height: 2.6875rem;
  display: flex;
  align-items: center;
  justify-content: flex-start;
}
.rectangle-16 {
  background: rgba(255, 255, 255, 0.1);
  border-radius: 0.3125rem;
  width: 10.0625rem;
  height: 2.25rem;
}
.change-password {
  color: #ffffff;
  text-align: left;
  font-family: sans-serif,"Montserrat",;
  font-size: 0.875rem;
  font-weight: 400;
  width: 8.125rem;
  height: 1.4375rem;
  display: flex;
  align-items: center;
  justify-content: flex-start;
}
.secure-your-account {
  color: #ffffff;
  text-align: left;
  font-family: sans-serif,"Montserrat",;
  font-size: 1rem;
  font-weight: 400;
  width: 10.75rem;
  height: 2.6875rem;
  display: flex;
  align-items: center;
  justify-content: flex-start;
}
.rectangle-17 {
  background: rgba(255, 255, 255, 0.1);
  border-radius: 0.3125rem;
  width: 15.9375rem;
  height: 2.25rem;
}
.first-name {
  color: #ffffff;
  text-align: left;
  font-family: sans-serif,"Montserrat",;
  font-size: 1rem;
  font-weight: 400;
  width: 10.75rem;
  height: 2.6875rem;
  display: flex;
  align-items: center;
  justify-content: flex-start;
}
.rectangle-18 {
  border-radius: 0.3125rem;
  border-style: solid;
  border-color: rgba(255, 255, 255, 0.25);
  border-width: 0.0625rem;
  width: 26.75rem;
  height: 2.25rem;
}
.last-name {
  color: #ffffff;
  text-align: left;
  font-family: sans-serif,"Montserrat",;
  font-size: 1rem;
  font-weight: 400;
  width: 10.75rem;
  height: 2.6875rem;
  display: flex;
  align-items: center;
  justify-content: flex-start;
}
.rectangle-19 {
  border-radius: 0.3125rem;
  border-style: solid;
  border-color: rgba(255, 255, 255, 0.25);
  border-width: 0.0625rem;
  width: 26.75rem;
  height: 2.25rem;
}
.phone {
  color: #ffffff;
  text-align: left;
  font-family: sans-serif,"Montserrat",;
  font-size: 1rem;
  font-weight: 400;
  width: 10.75rem;
  height: 2.6875rem;
  display: flex;
  align-items: center;
  justify-content: flex-start;
}
.rectangle-20 {
  border-radius: 0.3125rem;
  border-style: solid;
  border-color: rgba(255, 255, 255, 0.25);
  border-width: 0.0625rem;
  width: 12.625rem;
  height: 2.25rem;
}
.enable-2-factor-authentication {
  color: #ffffff;
  text-align: left;
  font-family: sans-serif,"Montserrat",;
  font-size: 0.875rem;
  font-weight: 400;
  width: 14rem;
  height: 1.4375rem;
  display: flex;
  align-items: center;
  justify-content: flex-start;
}
.rectangle-21 {
  border-radius: 0.3125rem;
  border-style: solid;
  border-color: #ffffff;
  border-width: 0.0625rem;
  width: 12.6875rem;
  height: 2.25rem;

}
.add-phone-number {
  color: #ffffff;
  text-align: center;
  font-family: sans-serif,"Montserrat",;
  font-size: 0.875rem;
  font-weight: 400;
  width: 10.625rem;
  height: 1.4375rem;
  display: flex;
  align-items: center;
  justify-content: center;
}
.alternative-email-for-alerts {
  color: #ffffff;
  text-align: left;
  font-family: sans-serif,"Montserrat",;
  font-size: 1rem;
  font-weight: 400;
  width: 10.75rem;
  height: 2.6875rem;
  display: flex;
  align-items: center;
  justify-content: flex-start;
}
.rectangle-22 {
  border-radius: 0.3125rem;
  border-style: solid;
  border-color: rgba(255, 255, 255, 0.25);
  border-width: 0.0625rem;
  width: 12.625rem;
  height: 2.25rem;
}
.rectangle-23 {
  border-radius: 0.3125rem;
  border-style: solid;
  border-color: #ffffff;
  border-width: 0.0625rem;
  width: 12.6875rem;
  height: 2.25rem;
}
.add-email {
  color: #ffffff;
  text-align: center;
  font-family: sans-serif,"Montserrat",;
  font-size: 0.875rem;
  font-weight: 400;
  width: 10.625rem;
  height: 1.4375rem;
  display: flex;
  align-items: center;
  justify-content: center;
}
.email-address-that-can-be-used-to-send-alerts {
  color: #ffffff;
  text-align: left;
  font-family:  sans-serif,"Montserrat-Light";
  font-size: 0.875rem;
  font-weight: 300;
  width: 26.375rem;
  height: 2.6875rem;
  display: flex;
  align-items: center;
  justify-content: flex-start;
}
.rectangle-24 {
  background: rgba(255, 255, 255, 0.1);
  border-radius: 0.3125rem;
  width: 12.6875rem;
  height: 2.25rem;
}
.save-changes {
  color: #ffffff;
  text-align: center;
  font-family: sans-serif,"Montserrat",;
  font-size: 1rem;
  font-weight: 400;
  width: 10.625rem;
  height: 1.4375rem;
  display: flex;
  align-items: center;
  justify-content: center;
}
.section-1-1 {
  background: rgba(255, 255, 255, 0.02);
  width: 45rem;
  height: 38.8125rem;
}
.public-details {
  color: #ffffff;
  text-align: left;
  font-family: sans-serif,"Montserrat-SemiBold"  !important;
  font-size: 1.125rem;
  font-weight: 600;
   width: 9.8125rem;
  height: 2.6875rem;
  display: flex;
  align-items: center;
  justify-content: flex-start;
}
.note-this-information-will-be-displayed-in-public-and-visible-for-all-users {
  color: #ffffff;
  text-align: left;
  font-family:  sans-serif,"Montserrat-Light" !important;
  font-size: 0.875rem;
  font-weight: 300;
  width: 35.8125rem;
  height: 2.6875rem;
  display: flex;
  align-items: center;
  justify-content: flex-start;
}
.name-nickname {
  color: #ffffff;
  text-align: left;
  font-family: sans-serif,"Montserrat" !important;
  font-size: 1rem;
  font-weight: 400;
  width: 9.8125rem;
  height: 2.6875rem;
  display: flex;
  align-items: center;
  justify-content: flex-start;
}
.rectangle-155 {
  border-radius: 0.3125rem;
  border-style: solid;
  border-color: #ffffff;
  border-width: 0.0625rem;
  width: 10.3125rem;
  height: 2.25rem;
  }
.cse-maverick2 {
  color: #d3d9d5;
  text-align: left;
  font-family: sans-serif,"Montserrat" !important;
  font-size: 0.875rem;
  font-weight: 400;
   width: 6.625rem;
  height: 1.4375rem;
  display: flex;
  align-items: center;
  justify-content: flex-start;
}
.country {
  color: #ffffff;
  text-align: left;
  font-family: sans-serif,"Montserrat" !important;
  font-size: 1rem;
  font-weight: 400;
  width: 9.8125rem;
  height: 2.6875rem;
  display: flex;
  align-items: center;
  justify-content: flex-start;
}
.rectangle-25 {
  border-radius: 0.3125rem;
  border-style: solid;
  border-color: #ffffff;
  border-width: 0.0625rem;
  width: 10.3125rem;
  height: 2.25rem;
  position: absolute;
  left: 16.375rem;
  top: 11.625rem;
}
.sri-lanka {
  color: #d3d9d5;
  text-align: left;
  font-family: sans-serif,"Montserrat" !important;
  font-size: 0.875rem;
  font-weight: 400;
   width: 6.625rem;
  height: 1.4375rem;
  display: flex;
  align-items: center;
  justify-content: flex-start;
}
.profile-picture-avatar-logo {
  color: #ffffff;
  text-align: left;
  font-family: sans-serif,"Montserrat" !important;
  font-size: 1rem;
  font-weight: 400;
  width: 10.75rem;
  height: 2.6875rem;
  display: flex;
  align-items: center;
  justify-content: flex-start;
}
.jpg-gif-or-png-files-supported-max-file-size-700-kb {
  color: #ffffff;
  text-align: left;
  font-family:  sans-serif,"Montserrat-Light" !important;
  font-size: 0.75rem;
  font-weight: 300;
  width: 12.375rem;
  height: 2rem;
  display: flex;
  align-items: center;
  justify-content: flex-start;
}
.rectangle-29 {
  background: rgba(255, 255, 255, 0.1);
  border-radius: 0.3125rem;
  width: 12.6875rem;
  height: 2.25rem;
}
.rectangle-282 {
  border-radius: 0.3125rem;
  width: 6.875rem;
  height: 6.875rem;
  object-fit: cover;
}
.upload-photo {
  color: #ffffff;
  text-align: center;
  font-family: sans-serif,"Montserrat" !important;
  font-size: 1rem;
  font-weight: 400;
   width: 8.625rem;
  height: 1.4375rem;
  display: flex;
  align-items: center;
  justify-content: center;
}
.rectangle-26 {
  background: rgba(255, 255, 255, 0.1);
  border-radius: 0.3125rem;
  width: 12.6875rem;
  height: 2.25rem;
}
.delete-photo {
  color: #ffffff;
  text-align: center;
  font-family: sans-serif,"Montserrat" !important;
  font-size: 1rem;
  font-weight: 400;
  width: 8.625rem;
  height: 1.4375rem;
  display: flex;
  align-items: center;
  justify-content: center;
}
.vector6 {
  width: 3.47%;
  height: 3.54%;
  overflow: visible;
}
.rectangle-242 {
  background: rgba(255, 255, 255, 0.1);
  border-radius: 0.3125rem;
  width: 12.6875rem;
  height: 2.25rem;
}
.save-changes2 {
  color: #ffffff;
  text-align: center;
  font-family: sans-serif,"Montserrat" !important;
  font-size: 1rem;
  font-weight: 400;
  display: flex;
  align-items: center;
  justify-content: center;
}
.ChangeEmail {
  width: auto; height: 2.25rem; background: rgba(255, 255, 255, 0.10); border-radius: 0.3125rem;color: #ffffff;
    }
    .stretch-card>.card {
      width: 100%;
      min-width: 100%
  }
 
  body {
      background-color: #f9f9fa
  }
 
  .flex {
      -webkit-box-flex: 1;
      -ms-flex: 1 1 auto;
      flex: 1 1 auto
  }
 
  /* @media (max-width:61.99875rem) {
      .padding {
          padding: 1.5rem
      }
  } */
 
  /* @media (max-width:47.99875rem) {
      .padding {
          padding: 1rem
      }
  } */
 
  .padding {
      padding: 3rem
  }
 .box {
     position: relative;
     border-radius: 0.1875rem;
     background: #ffffff;
     border-top: 0.1875rem solid #d2d6de;
     margin-bottom: 1.25rem;
     width: 100%;
     box-shadow: 0 0.0625rem 0.0625rem rgba(0,0,0,0.1);
 }
 
 .box-header.with-border {
     border-bottom: 0.0625rem solid #f4f4f4;
 }
 
 .box-header {
     color: #444;
     display: block;
     padding: 0.625rem;
     position: relative;
 }
 
 .box-header:before, .box-body:before, .box-footer:before, .box-header:after, .box-body:after, .box-footer:after {
     content: " ";
     display: table;
 }
 
 .box-header .box-title {
     display: inline-block;
     font-size: 1.125rem;
     margin: 0;
     line-height: 1;
 }
 
 h1, h2, h3, h4, h5, h6, .h1, .h2, .h3, .h4, .h5, .h6 {
  font-family: sans-serif,"Montserrat" !important;
 }
 
 .box-header:after, .box-body:after, .box-footer:after {
     content: " ";
     display: table;
 }
 
 .box-body {
     border-top-left-radius: 0;
     border-top-right-radius: 0;
     border-bottom-right-radius: 0.1875rem;
     border-bottom-left-radius: 0.1875rem;
     padding: 0.625rem;
 }
 
 .box-body>.table {
     margin-bottom: 0;
 }
 
 .table-bordered {
     border: 0.0625rem solid #f4f4f4;
 }
 
 .table {
     width: 100%;
     max-width: 100%;
     margin-bottom: 1.25rem;
 }
 
 table {
     background-color: transparent;
 }
 
 .table tr td .progress {
     margin-top: 0.3125rem;
 }
 
 .progress-bar-danger {
     background-color: #dd4b39;
 }
 .progress-xs {
     height: 0.4375rem;
 }
 
 .bg-red{
     background-color: #dd4b39 !important;
 color:#fff;
 }
 
 .badge {
     display: inline-block;
     min-width: 0.625rem;
     padding: 0.1875rem 0.4375rem;
     font-size: 0.75rem;
     font-weight: 700;
     line-height: 1;
     color: #fff;
     text-align: center;
     white-space: nowrap;
     vertical-align: middle;
     background-color: #777;
     border-radius: 0.625rem;
 }
 
 .progress-bar-yellow, .progress-bar-warning {
     background-color: #f39c12;
 }
 
 .bg-yellow{
     background-color: #f39c12;
 }
 
 .progress-bar-primary {
     background-color: #3c8dbc;
 }
 
 .bg-light-blue{
     background-color: #3c8dbc;
 }
 
 .progress-bar-success {
     background-color: #00a65a;
 }
 
 .bg-green{
     background-color: #00a65a;
 }
 
 .box-footer {
     border-top-left-radius: 0;
     border-top-right-radius: 0;
     border-bottom-right-radius: 0.1875rem;
     border-bottom-left-radius: 0.1875rem;
     border-top: 0.0625rem solid #f4f4f4;
     padding: 0.625rem;
     background-color: #fff;
 }
 
 .pull-right {
     float: right!important;
 }
 
 .pagination>li {
     display: inline;
 }
 
 .pagination-sm>li:first-child>a, .pagination-sm>li:first-child>span {
     border-top-left-radius: 0.1875rem;
     border-bottom-left-radius: 0.1875rem;
 }
 
 .pagination>li:first-child>a, .pagination>li:first-child>span {
     margin-left: 0;
     border-top-left-radius: 0.25rem;
     border-bottom-left-radius: 0.25rem;
 }
 
 .pagination>li>a {
     background: #fafafa;
     color: #666;
 }
 
 .pagination-sm>li>a, .pagination-sm>li>span {
     padding: 0.3125rem 0.625rem;
     font-size: 0.75rem;
     line-height: 1.5;
 }
 
 .pagination>li>a, .pagination>li>span {
     position: relative;
     float: left;
     padding: 0.375rem 0.75rem;
     margin-left: -0.0625rem;
     line-height: 1.42857143;
     color: #337ab7;
     text-decoration: none;
     background-color: #fff;
     border: 0.0625rem solid #ddd;
 }
 
 a {
     background-color: transparent;
 }
 .table-fixed{
  width: 100%;
  background-color: #f3f3f3;
  tbody{
    height:0.625rem;
    overflow-y:auto;
    width: 100%;
    }
  thead,tbody,tr,td,th{
    display:block;
  }
  tbody{
    td{
      float:left;
    }
  }
  thead {
    tr{
      th{
        float:left;
       background-color: #f39c12;
       border-color:#e67e22;
      }
    }
  }
}

.bigWrapper {
  margin: 0 auto;
  height: 100vh;
  display: flex;
  justify-content: center;
  align-content: center;
  align-items: center;
  flex-wrap: wrap;
}
.donutTarget {
  display: inline-block;
  vertical-align: top;
}
.donut {
  height: 100%;
  width: 100%;
  position: relative;
  margin: 0 15% 15% 0;
}
.donut::before {
  content: "";
  display: block;
  position: absolute;
  z-index: 1;
  width: 50%;
  height: 50%;
  background: #3c3a3f;
  border-radius: 50%;
  top: 25%;
  left: 25%;
}
.donut::after {
  content: "";
  display: block;
  width: 60%;
  height: 1%;
  border-radius: 50%;
  box-shadow: 0 0 1.5% 2% rgba(0, 0, 0, 0.1);
  margin: 110% auto;
}

/*
Change this width and height to determine the size of the donut. DIV MUST BE SQUARE unless you want to see some crazy stuff.
*/

.donutWrapper {
  width: 70vw;
  height: 70vw;
  margin: 0 auto;
  display: block;
  position: relative;
  overflow: hidden;
  float: left;
}

/*
Mess with the media queries to see how things look on different device screens and viewports. I just have some example queries set up:
*/
/* 
@media screen and (min-width: 62.5rem) {
  .donutWrapper {
    width: 80vh;
    height: 80vh;
  }
}
@media screen and (max-width: 62.5rem) {
  .donutWrapper {
    width: 55vh;
    height: 55vh;
  }
}
@media screen and (min-height: 62.5rem) {
  .donutWrapper {
    width: 50vh;
    height: 50vh;
  }
} */
.legendWrapper {
  display: block;
  margin: 1.25rem;
  float: right;
}
.chunk {
  position: absolute;
  width: 100%;
  height: 100%;
  clip-path: polygon(50% 50%, 100% 0%, 0% 0%, 0% 100%);
  animation: makeDonut 1s;
}
.chunk span {
  display: block;
  position: absolute;
  top: 0;
  left: 0;
  background-color: black;
  width: 100%;
  height: 100%;
  border-radius: 50%;
  clip-path: polygon(50% 50%, 100% 0%, 0% 0%, 0% 100%);
}
.legend {
  list-style-type: none;
  padding: 0;
  background: #616063;
  padding: 0.9375rem;
  font-size: 0.8125rem;
  color: #ffffff;
  box-shadow: 0.0625rem 0.0625rem 0 #2c2c2d, 0.125rem 0.125rem 0 #2c2c2d;
  float: left;
}
.legend li {
  width: 6.875rem;
  height: 1.25em;
  margin-bottom: 0.7em;
  padding-left: 0.5em;
  border-left: 1.25em solid black;
}
.legend em {
  font-style: normal;
}
.legend span {
  float: right;
}
p {
  color: white;
  clear: both;
}
.crud-icon {
  position: relative;
  display: inline-block;
  padding: 0.625rem;
  border-radius: 50%;
  box-sizing: border-box;
  cursor: pointer; }
  .crud-icon.disabled {
    cursor: default; }
  .crud-icon > svg {
    position: relative;
    top: -0.0625rem; }
  .crud-icon > div {
    z-index: 300;
    visibility: hidden;
    opacity: 0;
    position: absolute;
    padding: 0.25rem 0.4375rem 0.1875rem;
    top: 2.125rem;
    left: 0;
    color: white;
    background-color: grey;
    border-radius: 0.1875rem;
    font-size: 0.75rem;
    font-style: normal; }
  .crud-icon:hover {
    transition: background-color 0.2s linear 0.1s; }
    .crud-icon:hover > div {
      opacity: 1;
      visibility: visible;
      transition: opacity 0.3s linear 0.3s, visibility 0s linear 0.3s; }
  .crud-icon.dark.disabled > svg {
    fill: silver; }
  .crud-icon.dark > svg {
    fill: #fafafa; }
  .crud-icon.dark:hover:not(.disabled) {
    background-color: #616161; }
    .crud-icon.dark:hover:not(.disabled) > svg {
      fill: white; }
  .crud-icon.light.disabled > svg {
    fill: silver; }
  .crud-icon.light > svg {
    fill: #424242; }
  .crud-icon.light:hover:not(.disabled) {
    background-color: #eeeeee; }
    .crud-icon.light:hover:not(.disabled) > svg {
      fill: black; }
    .crud-icon.light:hover:not(.disabled) > div {
      visibility: visible; }
  .crud-icon.tiny {
    height: 1.75rem;
    width: 1.75rem;
    padding: 0.5rem; }
    .crud-icon.tiny > svg {
      height: 0.75rem;
      width: 0.75rem;
      top: -0.25rem; }
    .crud-icon.tiny > div {
      top: 1.875rem; }
  .crud-icon.small {
    height: 2.375rem;
    width: 2.375rem; }
    .crud-icon.small > svg {
      height: 1.125rem;
      width: 1.125rem; }
    .crud-icon.small > div {
      top: 2.5rem; }
  .crud-icon.medium {
    height: 2.875rem;
    width: 2.875rem; }
    .crud-icon.medium > svg {
      height: 1.625rem;
      width: 1.625rem; }
    .crud-icon.medium > div {
      top: 2.875rem; }
  .crud-icon.large {
    height: 3.875rem;
    width: 3.875rem; }
    .crud-icon.large > svg {
      height: 2.625rem;
      width: 2.625rem; }
    .crud-icon.large > div {
      top: 3.75rem; }
  .crud-icon.big {
    height: 6.25rem;
    width: 6.25rem; }
    .crud-icon.big > svg {
      height: 5rem;
      width: 5rem; }
    .crud-icon.big > div {
      top: 5.875rem; }
  .crud-icon.huge {
    height: 8.75rem;
    width: 8.75rem; }
    .crud-icon.huge > svg {
      height: 7.5rem;
      width: 7.5rem; }
    .crud-icon.huge > div {
      top: 8.625rem;
      left: 0.625rem; }
      .chartContainer {
        margin: 0 auto;
        max-width: 100%;
        position: relative;
        width: 22.5rem;
      }
      .chartInner {
        background: rgba(255, 128, 0, 0.1);
        display: flex;
        flex-direction: column;
        justify-content: center;
        position: absolute;
        top: 22%;
        right: 20%;
        bottom: 20%;
        left: 20%;
        border-radius: 50%;
        padding: 1.25em 0;
        line-height: 120%;
        z-index: -1; /* Allow tooltips on top */
        overflow: hidden;
      }
      .chartLable {
        font-weight: 400;
      }
      .chartValue {
        font-size: 2.8125rem;
        font-weight: bold;
        padding-bottom: 0.625rem;
      }
      
      /* Generic styles */
      .App {
        font-family: sans-serif,"Montserrat" !important;
        text-align: center;
      }
      .custom {
        width: 6.25rem !important;
    }
    .App {
      display: flex;
      justify-content: space-around;
      flex-wrap: wrap;
    }
    .doughnutCenterTextPlugin {
      position: relative;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
      width: 80%;
      z-index: -10;
    }
    .circle {
      display: flex;
      width: 6.25rem;
      height: 6.25rem;
      background-color: green;
      border-radius: 50%;
    }
    .mytable {
      font-size : 0.875rem! important; 
  }
  .spinner-container {
    position: absolute;
    height: 100vh; 
    width: 100vw; 
    display: flex;  
    align-items: center;
    justify-content: center;
    text-wrap:nowrap ;
  }
  
  portfoliobtn  .spinner {
    width: 3.125rem;
    height: 3.125rem;
    border: 0.3125rem   
   solid #f3f3f3;
    border-radius: 50%;
    border-top-color: #3498db;
    animation: spin 1s linear infinite;
    position: 'absolute';
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
    align-items: 'center';
    justify-content: 'center';
  }
  
  .basic-button
  {
    font-family: "Montserrat" !important;
    background: #6c757d !important;
    color: #fff !important;
    border-radius: 0.3125rem;
    padding: 5;
    width:28vh;
    }
  .basic-menu
  {
    font-family: "Montserrat" !important;
    background: #6c757d !important;
    color: #fff !important;
    border-radius: 0.3125rem;
  }
  .menu-item{
    font-family: sans-serif,"Montserrat" !important;
    background: #6c757d !important;
    color: #fff !important;
  }
 .menu-item:hover{
  font-family: sans-serif,"Montserrat" !important;
  background: #6c757d !important;
  color: #000 !important;

 }
.css-1wuw8dw-MuiBreadcrumbs-separator {
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
    margin-left: 0.5rem;
    margin-right: 0.5rem;
}
user agent stylesheet
li {
    display: list-item;
    text-align: -webkit-match-parent;
    unicode-bidi: isolate;
}

.css-4pdmu4-MuiBreadcrumbs-ol {
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-flex-wrap: wrap;
    -webkit-flex-wrap: wrap;
    -ms-flex-wrap: wrap;
    flex-wrap: wrap;
    -webkit-align-items: center;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    padding: 0;
    margin: 0;
    list-style: none;
}
user agent stylesheet
ol {
    list-style-type: decimal;
}
.css-1wuw8dw-MuiBreadcrumbs-separator {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  margin-left: 0.5rem;
  margin-right: 0.5rem;
}
.css-1wuw8dw-MuiBreadcrumbs-separator {
  display: flex;
  -webkit-user-select: none;
  user-select: none;
  margin-left: 0.5rem;
  margin-right: 0.5rem;
  color: #ecd0d0;
}
.css-1ey4h9j
{
  font-size: 0.8rem !important;
}
.css-159mmd9 {
  color: #ecd0d0 !important;
}
.css-xapz4p {
  color: #ecd0d0 !important;
}
.css-6hp17o-MuiList-root-MuiMenu-list {
  background: #6c757d !important;
}
.css-1e6y48t-MuiButtonBase-root-MuiButton-root{
  text-transform: '' !important;
}
.m_title {
  font-family: sans-serif,"Montserrat" !important;
  background: #6c757d !important;
  color: #fff !important;
  border-radius: 0.3125rem;  
  text-transform: lowercase
}

.m_title::first-letter {
  text-transform: uppercase
}
.css-n7mqi4-MuiTypography-root-MuiLink-root {
  font-family: sans-serif,"Montserrat" !important;
  font-size: 0.375rem;
  font-weight: 600;
}
.labelf {
  cursor: pointer;
  text-align: center;
}

.labelf h5 {
  color: #dcd5d5;
  font-size: 0.75rem;
  text-align: center;
}


.pfcss-span {
color: #D3D9D5;
 font-family: sans-serif,"Montserrat",;
   font-weight: 700;
    word-Wrap: break-word;
  font-size: 20px;

}

.pfcss-span2 {
  color: #D3D9D5;
  font-size: 20px;
  font-family: sans-serif,"Montserrat" !important;
    font-weight: 300;
     word-Wrap: break-word;
}
.joined-2-years-ago,
.joined-2-years-ago * {
    box-sizing: border-box;
}

.online,
.online * {
    box-sizing: border-box;
}

.online {
    color: #d3d9d5;
    text-align: left;
    font-family:  sans-serif,'Montserrat' !important;
    font-size: 0.75rem;
    font-weight: 400;
    position: relative;
    width: 4.875rem;
    height: 1.8125rem;
    display: flex;
    align-items: center;
    justify-content: flex-start;
}

.css-sshg3k {
  color: #ffffff !important;
}
.portfoliobtn {
background-color: rgba(217, 217, 217, 0.10) !important;
color: rgba(255, 255, 255, 1);
z-index: 2; /* Ensure the buttons are on top */
position: relative;
padding: 0.625rem;
margin: 0;
display: inline-flex;
align-items: center;
justify-content: center;
overflow: hidden;
white-space: nowrap;
display: inline-block;
text-overflow: ellipsis;
font-family:  sans-serif,'Montserrat' !important;;
}
.portfoliobtnsel {
  background-color: #19191C !important;
  color: rgba(255, 255, 255, 1);
  font-family:  sans-serif,'Montserrat' !important;;
  z-index: 2; /* Ensure the buttons are on top */
  position: relative;
  line-height:25px;
  }
  .portfoliobtnsel:hover {
    background-color: #262628;
    font-family:  sans-serif,'Montserrat' !important;;
    line-height:25px;
  }
  .css-pwxzbm {
    background-color: rgba(25, 25, 28, 1) !important; 
  }
  .gridContainer {
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(18.75rem, 1fr));
    gap: 0.625rem;
    width: 100vw;
    height: 100vh;
  }
  .piecharttest {
    position:  relative ;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
  }
  .pie-chart-parent { display: grid; }

  .pie-gridContainer { 
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(18.75rem, 1fr));
    gap: 0.625rem;
    width: 100vw;
    height: 100vh;
  }
  
  .pie-centered-text {
    display: flex;
    align-items: center;
    justify-content: center;
    height: 100%; /* Ensure it takes full height */
    font-size: 1.5rem; /* Adjust size for better visibility */
    color: white;
    text-align: center;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
  }
  .cpf_header
  {
    color: white; font-size: 14px; font-family: sans-serif,'Montserrat'; font-weight: 600; line-height: 19.60px; word-wrap: break-word;
  }
  .cpf_dtl
  {
    padding-left: 12px;
    padding-right: 12px;
    color: white; font-size: 14px; font-family: sans-serif,'Montserrat'; font-weight: 400; line-height: 19.60px; word-wrap: break-word;
  }
  .cpfstrt_dtl
  {
    padding-left: 12px;
    padding-right: 12px;
    color: white; font-size: 14px; font-family: sans-serif,'Montserrat'; font-weight: 600; line-height: 19.60px; word-wrap: break-word;
  }
  .cpf_dtl_ticker
  {
    color: white; font-size: 12px; font-family: sans-serif,'Montserrat'; font-weight: 400; line-height: 16.80px; word-wrap: break-word;
  }


  .tppf_header
  {
    color: white; font-size: 0.875rem; font-family:  sans-serif,'Montserrat'; font-weight: 600; line-height: 1.225rem; word-wrap: break-word;
  }
  .tppf_dtl
  {
    color: white; font-size: 0.875rem; font-family:  sans-serif,'Montserrat'; font-weight: 600; line-height: 1.225rem; word-wrap: break-word;
  }
  .tppf_dtl_ticker
  {
    color: white; font-size: 0.75rem; font-family:  sans-serif,'Montserrat'; font-weight: 400; line-height: 1.05rem; word-wrap: break-word;text-align:start !important;
  }
.fininfo_title
{
  color: white; font-size: 18px; font-family:  sans-serif,'Montserrat'; font-weight: 700; line-height: 22px; word-wrap: break-word;
}
.fininfo_header
{
  flex: 1 1 0; color: white; font-size: 0.8125rem; font-family:  sans-serif,'Montserrat'; font-weight: 600; text-transform: capitalize; line-height: 1rem; letter-spacing: 0.01625rem; word-wrap: break-word;
}
.fininfo_dtl
{
  align-content: center; color: white; font-size: 0.875rem; font-family:  sans-serif,'Montserrat'; font-weight: 600; line-height: 1.225rem; word-wrap: break-word;
}
.css-3mf706 {
  color: white !important;
}
.MuiMenu-root {
  z-index: 1; /* Ensure the menu doesn't overlap */
}
.col-md-8 {
  overflow: visible;
}
.css-ob9lhs {
  font-family:  sans-serif,'Montserrat' !important;;color: white; font-size: 0.875rem;
}
.numSpanpf {
  display: inline-block;
  width: 40%; /* Ensures it takes full width */
  text-align: right; /* Aligns the text to the right */
}

.numSpanpftd {
  display: flex;
  justify-content: center; /* Centers the content horizontally within the cell */
  align-items: center; /* Vertically centers content */
  font-family: 'Montserrat';
}
/* Extra Small Devices, Phones (Up to 20rem) */
/* @media only screen and (max-width: 20rem) {
  .btn-sq {
    width: 5rem;
    height: 2rem;
    font-size: 0.5rem;
  }
  .fs-6 {
    font-size: .625rem !important;
  }
} */

/* Small Devices, Phones (Up to 30rem) */
/* @media only screen and (min-width: 20rem) and (max-width: 30rem) {
  .btn-sq {
    width: 6rem;
    height: 2.5rem;
    font-size: 0.625rem;
    width: 100%;
  }
  .fs-6 {
    font-size: .625rem !important;
  }
} */

/* Tablets and Small Devices (Up to 48rem) */
/* @media only screen and (min-width: 30rem) and (max-width: 48rem) {
  .btn-sq {
    height: 3rem;
    font-size: 0.75rem;
    width: 100%; 
  }
} */

/* Medium Devices and Tablets (Up to 62rem) */
/* @media only screen and (min-width: 48rem) and (max-width: 62rem) {
  .btn-sq {
    height: 3.5rem;
    font-size: 0.875rem;
    width: 80%; 
  }
} */

/* Large Devices, Desktops (Above 62rem) */
/* @media only screen and (min-width: 62rem) {
  .btn-sq {
    height: 4rem;
    font-size: 1rem;
    width: 60%; 
  }
} */
/* Large devices (large desktops) */
/* @media (min-width: 992px) {
  .btn-sq {
     height: 4rem;
    font-size: 1rem;
    width: 50%; 
  }
} */

.fixed_header {
  table-layout: fixed;
  width: 100%;
  border-collapse: collapse;
}
.fixed_header tbody {
  display: block;
  width: 100%;
  overflow: auto;
  height: 250px;
}
.fixed_header thead tr {
  display: block;
}
.fixed_header thead {
  color: #fff;
}
.fixed_header th,
.fixed_header td {
  padding: 5px;
}
.newsheader
{
  color: white; font-size: 18px; font-family:  sans-serif,'Montserrat'; font-weight: 700; text-transform: uppercase; word-wrap: break-word;
}
.newsdtl
{
  color: white; font-size: 13px; font-family:  sans-serif,'Montserrat'; font-weight: 400; word-wrap: break-word;
}

/* Large Devices, Desktops (Above 62rem) */
/* @media only screen and (min-width: 62rem) {
  .scrollevent {
    overflow-x: hidden;
  }
} */
.scrollevent {
  overflow-x: hidden;
}
.mytablefi {
  width: 100%;
  font-family:  sans-serif,'Montserrat' !important;
  font-size: 0.9rem;
}
.mytablefi th, .mytablefi td {
  padding: 8px 12px;
  text-align: left;
}
.mytablefi th {
  background-color: #2b2b2b;
  color: #fff;
  font-weight: 600;
}
.mytablefi td {
  text-align: start;
  padding-right: 0px;
  /* align-content: start; */
}
.mytablefitd {
  text-align: center;
  padding-right: 0px;
  align-content: center;
  flex: 1 1 0; color: white; 
  font-size: 13px; 
  font-family:  sans-serif,'Montserrat' !important;
  font-weight: 400;
   line-height: 20px; 
   word-wrap: break-word;
}

.mytablefi img {
  margin-right: 8px;
  vertical-align: middle;
}
.mytablefi tr:hover {
  background-color: #333333;
}
.text-nowrap {
  white-space: nowrap;
}
.table-container {
  overflow-x: auto;
  padding: 10px;
}

.mytablefi tbody tr td.td_start {
  text-align:start !important;
}
.mytablefi tbody tr td.td_end {
  text-align:end !important;
}
.css-1ujsas3 {
  height: 36px !important; 
  background: rgba(255, 255, 255, 0.10) !important; 
  border-radius: 4px !important; 
  width: 256.47px !important; 
  color: white !important; 
  text-transform: capitalize !important; 
}

.margin-right{
  margin-right: 80px !important;
}
.fininfo_header
{
  flex: 1 1 0; color: white; font-size: 13px; font-family:  sans-serif,'Montserrat'; font-weight: 600; text-transform: capitalize; line-height: 16px; letter-spacing: 0.26px; word-wrap: break-word;
}
.fininfo_dtl
{
flex: 1 1 0; color: white; font-size: 13px; font-family:  sans-serif,'Montserrat'; font-weight: 400; line-height: 20px; word-wrap: break-word;
}
.MuiTablePagination-displayedRows
{
  margin-top: "1em";
  margin-bottom: "1em";
}
.browser {
  margin: 0 !important;
  padding: 0 !important;
  height: auto !important;
}
.trackcss1
{
color:#D3D9D5 !important;
font-size:20px !important; 
letter-spacing:0 !important;
font-family:sans-serif !important;
font-weight:700 !important;
letter-spacing: 0.02em !important;

}
.trackcss2
{
  color:#D3D9D5 !important;
  font-size:20px !important; 
  letter-spacing:0 !important;
  font-family:sans-serif !important;
  font-weight:400 !important;
  text-decoration: none;
  filter: brightness(80%);
  letter-spacing: 0.02em !important;
}
.tp_dtl{
  font-family:sans-serif,'Montserrat-SemiBold' !important;
  font-size:10px;
  font-weight: 400;
  white-space: nowrap;
  line-height: 14px;
  }
  .tp_dtl-val{
    font-family:sans-serif,'Montserrat-SemiBold' !important;
    font-size:10px;
    font-weight: 400;
    white-space: nowrap;
    line-height: 14px;
    text-align: right !important;
    }
.tp_header{
font-family:sans-serif,'Montserrat-SemiBold' !important;
color: #FFF;
font-size: 12px;
font-style: normal;
font-weight: 700 !important;
line-height: 14px;
}
.tpg_dtl{
  font-family:sans-serif,'Montserrat-SemiBold' !important;
  font-size:10px;
  font-weight: 400;
  white-space: nowrap;
  line-height: 14px;
  }
.tpg_dtl-val{
  font-family:sans-serif,'Montserrat-SemiBold' !important;
  font-size:10px;
  font-weight: 400;
  white-space: nowrap;
  line-height: 14px;
  text-align: right !important;
  }
.tpg_header{
font-family:sans-serif,'Montserrat-SemiBold' !important;
color: #FFF;
font-size: 12px;
font-style: normal;
font-weight: 700 !important;
line-height: 14px;
}
.font-family-sans-serif {
  font-family:sans-serif,'Montserrat' !important;
}
.cpfcssh1
{
background:var(--light-theme-gray-light-grey, #dfe5ef);
-webkit-flex-shrink:0;
-ms-flex-shrink:0;
flex-shrink:0;
width:663px;
height:1px;
position:relative;
-webkit-flex-direction:row;
-ms-flex-direction:row;
flex-direction:row;
gap:16px;
-webkit-align-items:end;
-webkit-box-align:end;
-ms-flex-align:end;
align-items:end;
-webkit-box-pack:end;
-webkit-justify-content:flex-end;
-ms-flex-pack:end;
justify-content:flex-end;
}
.cpfcssh2
{
display:flex;
-webkit-flex-direction:row;
-ms-flex-direction:row;
flex-direction:row;
-webkit-align-items:center;
-webkit-box-align:center;
-ms-flex-align:center;
align-items:center;
-webkit-box-pack:start;
-webkit-justify-content:flex-start;
-ms-flex-pack:start;
justify-content:flex-start;
-webkit-flex-shrink:0;
-ms-flex-shrink:0;
flex-shrink:0;
position:relative;
}
.cpfcssh3
{
color:#ffffff;
-webkit-text-align:left;
text-align:left;
font-family: sans-serif,'Montserrat-SemiBold';
font-size:14px;
line-height:140%;
letter-spacing:-0.01em;
font-weight:600;
position:relative;
}
.cpfcssh4
{
  color:#ffffff;
  -webkit-text-align:left;
  text-align:left;
  font-family: sans-serif,'Montserrat-SemiBold';
  font-size:14px;
  line-height:140%;
  letter-spacing:-0.01em;
  font-weight:600;
  position:relative;
  width:100px;
  }
  .cpfcssh5
  {
    display:flex;
    -webkit-flex-direction:row;
    -ms-flex-direction:row;
    flex-direction:row;
    -webkit-align-items:end;
    -webkit-box-align:end;
    -ms-flex-align:end;
    align-items:end;
    -webkit-box-pack:end;
    -webkit-justify-content:flex-end;
    -ms-flex-pack:end;
    justify-content:flex-end;
    -webkit-flex-shrink:0;
    -ms-flex-shrink:0;
    flex-shrink:0;
    width:663px;
    position:relative;
    gap:16px;
    }
    .cpfcssh6
    {
      display:flex;
      -webkit-flex-direction:row;
      -ms-flex-direction:row;
      flex-direction:row;
      -webkit-align-items:center;
      -webkit-box-align:center;
      -ms-flex-align:center;
      align-items:center;
      -webkit-box-pack:start;
      -webkit-justify-content:flex-start;
      -ms-flex-pack:start;
      justify-content:flex-start;
      -webkit-flex:1;
      -ms-flex:1;
      flex:1;
      position:relative;
      gap:12px;
      }
      .cpfcssh7
      {
        color:#ffffff;
        -webkit-text-align:right;
        text-align:right;
        font-family: sans-serif,'Montserrat-SemiBold';
        font-size:14px;
        line-height:140%;
        letter-spacing:-0.02em;
        font-weight:400;
        position:relative;
        }
        .cpfcssh8
        {
          display:flex;
          -webkit-flex-direction:row;
          -ms-flex-direction:row;
          flex-direction:row;
          gap:0px;
          -webkit-align-items:center;
          -webkit-box-align:center;
          -ms-flex-align:center;
          align-items:center;
          -webkit-box-pack:start;
          -webkit-justify-content:flex-start;
          -ms-flex-pack:start;
          justify-content:flex-start;
          -webkit-flex-shrink:0;
          -ms-flex-shrink:0;
          flex-shrink:0;
          width:100px;
          position:relative;
          }
          .cpfcssh9
          {
            color:#ffffff;
            -webkit-text-align:left;
            text-align:left;
            font-family: sans-serif,'Montserrat-SemiBold';
            font-size:14px;
            line-height:140%;
            letter-spacing:-0.01em;
            font-weight:600;
            position:relative;
            -ms-flex-item-align:stretch;
            align-self:stretch;
            }
            .cpfcssh10
            {
              display:flex;
              -webkit-flex-direction:column;
              -ms-flex-direction:column;
              flex-direction:column;
              gap:4px;
              -webkit-align-items:flex-start;
              -webkit-box-align:flex-start;
              -ms-flex-align:flex-start;
              align-items:flex-start;
              -webkit-box-pack:start;
              -webkit-justify-content:flex-start;
              -ms-flex-pack:start;
              justify-content:flex-start;
              -webkit-flex:1;
              -ms-flex:1;
              flex:1;
              position:relative;
              }
              .cpfcssh11
              {
                display:flex;
                -webkit-flex-direction:row;
                -ms-flex-direction:row;
                flex-direction:row;
                -webkit-align-items:center;
                -webkit-box-align:center;
                -ms-flex-align:center;
                align-items:center;
                -webkit-box-pack:start;
                -webkit-justify-content:flex-start;
                -ms-flex-pack:start;
                justify-content:flex-start;
                -webkit-flex:1;
                -ms-flex:1;
                flex:1;
                position:relative;
                gap:0px;
                }

                .cpfcssh12
                {
              width:100px;
              gap:0px; 
                }
                .cpfcssh13
                {
              width:69px;
              gap:0px; 
                }
                .cpfcssh14
                {
              width:663px;
              gap:16px; 
                }
                .cpfcssh15
                {
                  color:#ffffff;
                  -webkit-text-align:left;
                  text-align:left;
                  font-family: sans-serif,'Montserrat-SemiBold';
                  font-size:14px;
                  line-height:140%;
                  letter-spacing:-0.01em;
                  font-weight:600;
                  position:relative;
                  -ms-flex-item-align:stretch;
                  align-self:stretch;
                  }
                  .cpfcssh16
                  {
                    color:#ffffff;
                    -webkit-text-align:left;
                    text-align:left;
                    font-family: sans-serif,'Montserrat-SemiBold';
                    font-size:12px;
                    line-height:140%;
                    font-weight:400;
                    position:relative;
                    -ms-flex-item-align:stretch;
                    align-self:stretch;
                    }
                    .cpfcssh17
                    {
                      display:flex;
                      -webkit-flex-direction:row;
                      -ms-flex-direction:row;
                      flex-direction:row;
                      gap:0px;
                      -webkit-align-items:end;
                      -webkit-box-align:end;
                      -ms-flex-align:end;
                      align-items:end;
                      -webkit-box-pack:start;
                      -webkit-justify-content:flex-end;
                      -ms-flex-pack:end;
                      justify-content:flex-start;
                      -webkit-flex-shrink:0;
                      -ms-flex-shrink:0;
                      flex-shrink:0;
                      width:64px;
                      position:relative;
                      }
                      .cpfcssh18
                      {
                        color:#ffffff;
                        -webkit-text-align:end;
                        text-align:end;
                        font-family: sans-serif,'Montserrat-SemiBold';
                        font-size:14px;
                        line-height:140%;
                        letter-spacing:-0.02em;
                        font-weight:400;
                        position:relative;
                        }
.css-wywq3z-MuiSelect-select-MuiInputBase-input.css-wywq3z-MuiSelect-select-MuiInputBase-input.css-wywq3z-MuiSelect-select-MuiInputBase-input {
  padding-bottom: 0px;
  padding-top: 0px;
  margin-bottom: 16px;
 }
 .css-12ituji-MuiSvgIcon-root-MuiSelect-icon {
  top: 0 !important;
 }

.span-css
{
  display: inline-block;
  width: 65px;
  padding-right: 2px;
}
