.header {
    position: absolute;
    top: 0px;
    left: 0px;
    z-index: 1000;
    width: 100%;
    height: 89px;
}
.header-logo {
display: flex;
width: 336px;
height: 115px;
left: 0px; top: 0px;
}   
.header-menutext {
    color: #FFF;
    text-align: center;
    font-family:  sans-serif,'Montserrat';
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    text-transform: uppercase;
}  
.redbell
{
    width: 9;
    height: 10;
    left: 23;
    top: 0;
    position: absolute;
    background: #FF0000;

}
.dropdown-menu {
    background-color: #343a40; /* Dark background */
    color: #fff; /* White text */
    display: inline-block;
    position:absolute;
}

.dropdown-item {
    color: #fff; /* White text */
}

.dropdown-item:hover {
    background-color: #495057; /* Slightly lighter dark background on hover */
    color: #fff; /* White text on hover */
}
.dropdown-menur {
    left: auto;
    right: 0;
}
/* .nav-link {
    margin-right: 20px; 
}
.navbar-nav {
    gap: 40px; 
}
.navbar-nav > .nav-item{
    margin-left: 25px;
    margin-right: 25px;
} */
/* Base Button Styling */
.header-button {
    font-size: clamp(0.75rem, 1vw + 0.5rem, 1rem); /* Fluid font-size */
 }

/* Hover Effect */
.header-button:hover {
    color: '';
}

/* Media Query for Smaller Screens */
/* @media (max-width: 576px) {
    .header-button {
          font-size: 0.75rem !important;
          padding: 0.2rem 0.4rem !important;
          gap:2px !important;
    }
} */

/* Media Query for Larger Screens */
/* @media (min-width: 992px) {
    .header-button {
        font-size: 1rem !important;
        padding: 0.6rem 1.2rem !important;
        gap:3px !important;
    }
} */

.footer-logo
{
width: 336px;
 height: 115px; 
 left: 0px;
  top: 0px;
}
.container-fluid {
    padding: 0px !important;
}