
  /* Extra Small Devices, Phones (Up to 20rem) */
  /* @media only screen and (max-width: 20rem) {
    .body {
      font-size: 0.5rem;
      height: 2rem;
    }
  } */
  
  /* Small Devices, Phones (Up to 30rem) */
  /* @media only screen and (min-width: 20rem) and (max-width: 30rem) {
    .body {
      font-size: 0.625rem;
      height: 2.5rem;
    }
  } */
  
  /* Tablets and Small Devices (Up to 48rem) */
  /* @media only screen and (min-width: 30rem) and (max-width: 48rem) {
    .body {
      font-size: 0.75rem;
      height: 3rem;
    }
  } */
  
  /* Medium Devices and Tablets (Up to 62rem) */
  /* @media only screen and (min-width: 48rem) and (max-width: 62rem) {
    .body {
      font-size: 0.875rem;
      height: 3.5rem;
    }
  }
   */
  /* Large Devices, Desktops (Above 62rem) */
  /* @media only screen and (min-width: 62rem) {
  
  } */
  
  /* Large Devices, Large Desktops (min-width: 992px) */
  /* @media (min-width: 992px) {

  } */
  .footer-css { min-height: 100vh;}
.markets-copyright {
    /* border-top: 2px solid #fff; */
    padding-top: 5px;
}

.marketers-img {
    background-color: #605d5d;
    margin: 5px;
    border-radius: 2rem;
}

.marketers-img:hover {
    background-color: #ffffff;
}

footer {
    background: #333;
    color: #fff;
    text-align: center;
    padding: 1rem;
    position: relative;
    width: 100%;
  }

.link-colums {
    min-height: 150px;
}

.footer-container h2 {
    font-weight: bold;
    padding-bottom: 5px;
}

.footer-container ul,
.footer-container li {
    font-family: 'Times New Roman', Times, serif;
    color: #7e6f6f;
}

.link-colums a {
    text-decoration: none !important;
}

.footer-container li a:hover {
    background-color: gray;
    border-radius: 0.5rem;
    padding: 2px;
    color: #000;
}
.footer-textlong {
  color: #ffffff !important;
  text-align: justify !important;
  white-space: nowrap;
  font-family:  sans-serif,'Montserrat' !important;;
  font-size: 16px !important;
  font-weight: 300 !important;
  position: relative;
  height: 88px !important;
  display: flex !important;
  align-items: center !important;
}
.footer-textlogo {
    color: #FFF;
    font-family:  sans-serif,'Montserrat';
    font-size: 18px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    }
    .footer-logo-text{
        color: white; font-size: 18px; font-family:  sans-serif,'Montserrat'; font-weight: 400;
    }
    .footer-text {
        font-family:  sans-serif,'Montserrat';
        font-size: 1rem;
        line-height: 1.5;
      }
      
      /* @media (max-width: 767px) {
        .footer-text {
          font-size: 0.875rem;
        }
      } 
      /* Adjusting padding or margin for various screen sizes */

.container-fluid {
    padding: 0px; /* Default padding */
  }
  
  /* Tablets */
  /* @media (max-width: 1024px) {
    .container-fluid {
      padding: 15px;
      font-size: 0.75rem;
      text-align: center;
      display:    block;
    }
    .respcss
    {
      padding: 15px;
      font-size: 0.75rem;
      text-align: center;
      display:    block;
    }

    .footer-text{
      .about-text {
        font-size: 0.75rem;
        text-align: center;
    }
    }
    .footer-textlong {
      color: #ffffff !important;
      text-align: justify !important;
      font-family:  sans-serif,'Montserrat' !important;;
      font-size: 0.75rem;
      text-align: center;
      white-space: wrap;
    }
  }
   */
  /* Mobile */
  /* @media (max-width: 768px) {
    .container-fluid {
      padding: 10px;
      font-size: 0.5rem;
      text-align: center !important;
      justify-content: center;
      display: flex;
  flex-direction: row;

    }
    .respcss
    {
      padding: 10px;
      font-size: 0.5rem;
      text-align: center !important;
      justify-content: center;
      display: flex;
  flex-direction: row;
    }
    .about-text {
      font-size: 0.75rem;
      text-align: center;
  }

  .footer-textlong {
    color: #ffffff !important;
    text-align: justify !important;
    white-space: wrap;
    font-family:  sans-serif,'Montserrat' !important;;
    font-size: 0.75rem;
    text-align: center;
  }
  } */

  .footer-text{
    font-size: 0.75rem;
    text-align: center;
  }
  .img-fluid.d-md-none.text-nowrap.text-center {
    color: #ffffff;
    text-align: center;
    font-family: sans-serif, "Montserrat-Regular";
    font-size: 18px;
    font-weight: 400;
    display: flex;
    align-items: center;
    justify-content: center; /* Ensures horizontal centering */
    height: 100%; /* Makes sure it takes the full container height */
    padding: 10px; /* Optional for some spacing */
    box-sizing: border-box; /* Ensures padding doesn't affect centering */
  }