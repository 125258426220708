Base Styles
.body {
    /* font-size: clamp(0.5rem, 2vw + 0.5rem, 1rem); /* Fluid font-size */
    font-size: calc(16px + 1vw);
    line-height: calc(1.1em + 0.5vw);
  }

  /* Extra Small Devices, Phones (Up to 20rem) */
  @media only screen and (max-width: 20rem) {
    .body {
      font-size: 0.5rem;
      height: 2rem;
    }
  }
  
  /* Small Devices, Phones (Up to 30rem) */
  @media only screen and (min-width: 20rem) and (max-width: 30rem) {
    .body {
      font-size: 0.625rem;
      height: 2.5rem;
    }
  }
  
  /* Tablets and Small Devices (Up to 48rem) */
  @media only screen and (min-width: 30rem) and (max-width: 48rem) {
    .body {
      font-size: 0.75rem;
      height: 3rem;
    }
  }
  
  /* Medium Devices and Tablets (Up to 62rem) */
  @media only screen and (min-width: 48rem) and (max-width: 62rem) {
    .body {
      font-size: 0.875rem;
      height: 3.5rem;
    }
  }
  
  /* Large Devices, Desktops (Above 62rem) */
  @media only screen and (min-width: 62rem) {
    .body {
      font-size: 1rem;
      height: 4rem;
    }
  }
  
  /* Large Devices, Large Desktops (min-width: 992px) */
  @media (min-width: 992px) {
    .body {
      font-size: 1.125rem;
      height: 4.5rem;
    }
  }
  .header-logo {
    display: flex;
    width: 336px;
    height: 115px;
    left: 0px; top: 0px;
    }  

  .videoTag {
     position: absolute;
     top: 0;
     left: 0;
     width: 100%;
     height: 100%;
     object-fit: cover;
     filter: brightness(15%);
 }
 .videoTagAU {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    object-fit: cover;
    opacity:0.08;
 }
 .videoTagsingin {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    object-fit: cover;
    filter: brightness(50%);
}
 .landing-centered-text {
    position: absolute;
    top: 40%;
    left: 30%;
      transform: translate(-50%, -50%);
      color: #D3D9D5;

      font-family:  sans-serif,'Montserrat';
      font-size: 3.125rem;
      font-style: normal;
      font-weight: 700;
      line-height: normal;
}
.landing-h1 {
    color: #D3D9D5; 
    font-size: 3.4375rem; font-family:  sans-serif,'Montserrat';
     font-weight: 700; word-wrap: break-word

}
.Carousel_h
{
color: #15e55a;
font-family:  sans-serif,'Montserrat';
font-size: 2rem;
font-style: normal;
font-weight: 700;
line-height: normal;
display: flex;
width: auto;
height: 2.3570625rem;
position: absolute;
top: 50%;
transform: translateY(-50%);
}
/* .carousel-control-prev-icon {
    background-image: url(\images\carousel\icons8_left-round.png);
} */
.trendunner1
{
    color: #D3D9D5;
     font-size: 2.5rem;
     font-family:  sans-serif,'Montserrat';
     font-weight: 300;
     word-wrap: break-word; 
}
.trendunner2
{
    color: #D3D9D5;
     font-size: 2.5rem;
     font-family:  sans-serif,'Montserrat';
     font-weight: 700;
     word-wrap: break-word; 
}
.trendunner
{
    position: absolute;
    top: 30%;
    left: 40%;

    white-space: nowrap;
}
.trendunnersmall
{
    position: relative;
    top: 60%;
}
.trendunnersmall1
{
    color: #FFF;

    text-align: center;
    font-family:  sans-serif,'Montserrat';
    font-size: 1.25rem;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
}
.trendunnersmall2
{
    color: #FFF;

    text-align: center;
    font-family:  sans-serif,'Montserrat';
    font-size: 1.25rem;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
}
/* .carousel-control-prev {
    background-image: url('/images/carousel/left-round.png') !important;
} */
.carousel-control-prev-icon {
    width: 2.5rem;
    height: 2.5rem;
    background-color: rgba(0, 0, 0, 0.3);
    background-image: url('./images/carousel/left-round.png');
    background-size: 100%, 100%;
    border-radius: 50%;
    /* border: 0.125rem solid rgb(248, 244, 244); */
    left:0;
    margin-right:10rem;
  }
.carousel-control-next-icon {
    width: 2.5rem;
    height: 2.5rem;
    background-color: rgba(0, 0, 0, 0.3);
    background-image: url('./images/carousel/right-round.png');
    background-size: 100%, 100%;
    border-radius: 50%;
    /* border: 0.125rem solid rgb(248, 244, 244); */
    float: left;
    margin-left: 10rem;
}
.trading_text {
color: #D3D9D5;
 font-size: 55px !important;
  font-family:  sans-serif,'Montserrat'; 
  font-weight: 700 !important;
   word-wrap: break-word;
}
.tradingsmart_text {
    color: #D3D9D5;
    font-family:  sans-serif,'Montserrat';
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    font-size: 37px !important;
    text-align: center;
    }
    /* For Body Text */
.text-connect {
    font-size: 24px !important;
    text-align: center;
     color: #D3D9D5 !important;
    font-family:  sans-serif,'Montserrat';
font-size: 24px !important;
font-style: normal !important;
font-weight: 500 !important;
line-height: normal !important;
}
/* Centering and responsive padding */
.text-center {
    width: 100%;
    padding: 0 5%;
}

/* Responsive Spacing */
.container-fluid {
    padding-top: 1rem;
    padding-bottom: 1rem;
}
/* @media (max-width: 768px) {
    .trading_text {
        font-size: 1.5rem !important;
    }

    .tradingsmart_text {
        font-size: 1.25rem !important;
    }

    .text-connect {
        font-size: 1rem !important;
    }

} */
.fs-2 {
    font-size: 1.5rem !important;
}
    .component{
    display: flex;
flex-direction: column;
justify-content: center;
flex-shrink: 0;
    }
.text-connect
{
    color: #D3D9D5;
font-family:  sans-serif,'Montserrat';
font-size: 1.875rem;
font-style: normal;
font-weight: 500;
line-height: normal;
}
.crousal-big
{
    color: #D3D9D5;

font-family:  sans-serif,'Montserrat';
font-size: 2rem;
font-style: normal;
font-weight: 700;
line-height: normal;
}
.carousel_toptext 
{
    color: #D3D9D5 !important;
    text-align: center !important;
    font-family:  sans-serif,'Montserrat' !important;
    font-size: 24px !important;
    font-style: normal !important;
    font-weight: 400 !important;
    line-height: normal !important;
}
.section-7 {
    position: relative;
    width: 90rem;
    height: 47rem;
    margin: 0 0 0 0;
    background: #000000;
    z-index: 17;
    overflow: visible auto;
}
.wrapper-6 {
    position: relative;
    width: 68.1875rem;
    height: 36.75rem;
    margin: 5.125rem 0 0 10.9375rem;
    background: rgba(255, 255, 255, 0.05);
    z-index: 19;
    overflow: visible auto;
    border-radius: 1.25rem;
}
.img-5 {
    position: relative;
    width: 6.25rem;
    height: 4.25rem;
    margin: 1.5625rem 0 0 31rem;
    background: url(./images/pinkeye.png)
        no-repeat center;
    background-size: 100% 100%;
    z-index: 24;
}
.text-d {
    display: flex;
    align-items: center;
    justify-content: center;
    position: relative;
    width: 18.25rem;
    height: 3.375rem;
    margin: 0.75rem 0 0 25rem;
    color: #d3d9d5;
    font-family:  sans-serif,'Montserrat';
    font-size: 1.25rem;
    font-weight: 700;
    line-height: 1.52375rem;
    text-align: center;
    z-index: 20;
}
.wrapper-7 {
    position: relative;
    width: 62.625rem;
    height: 3.375rem;
    margin: 0 0 0 2.8125rem;
    font-family:  sans-serif,'Montserrat';
    font-size: 1rem;
    font-weight: 400;
    line-height: 1.219rem;
    text-align: center;
    z-index: 21;
}
.text-e {
    position: relative;
    color: #d3d9d5;
    font-family:  sans-serif,'Montserrat';
    font-size: 1rem;
    font-weight: 400;
    line-height: 1.219rem;
    text-align: center;
}
.text-f {
    position: relative;
    color: #d3d9d5;
    font-family:  sans-serif,'Montserrat';
    font-size: 1rem;
    font-weight: 700;
    line-height: 1.219rem;
    text-align: center;
}
.text-10 {
    display: block;
    position: relative;
    height: 1.875rem;
    margin: 1.125rem 0 0 30.9375rem;
    color: #d3d9d5;
    font-family:  sans-serif,'Montserrat';
    font-size: 1.25rem;
    font-weight: 700;
    line-height: 1.52375rem;
    text-align: left;
    white-space: nowrap;
    z-index: 22;
}
.group-5 {
    display: flex;
    align-items: center;
    justify-content: space-between;
    position: relative;
    width: 61.6875rem;
    height: 14.9375rem;
    margin: 0.4375rem 0 0 3.25rem;
    z-index: 38;
}
.group-6 {
    flex-shrink: 0;
    position: relative;
    width: 19.9375rem;
    height: 14.9375rem;
    background: rgba(255, 255, 255, 0.05);
    z-index: 26;
    border-radius: 1.25rem;
}
.wrapper-8 {
    position: relative;
    width: 2.4375rem;
    height: 2.4375rem;
    margin: 1.3125rem 0 0 8.75rem;
    z-index: 27;
    overflow: hidden;
}
.pic-8 {
    position: relative;
    width: 2.03125rem;
    height: 2.03125rem;
    margin: 0.203125rem 0 0 0.203125rem;
     background: url(./images/img-10.png)
        no-repeat center; 
    background-size: 100% 100%;
    z-index: 28;
}
.wrapper-9 {
    position: relative;
    width: 17.5625rem;
    height: 9.1875rem;
    margin: 1.1875rem 0 0 1.1875rem;
    font-family:  sans-serif,'Montserrat';
    font-size: 1rem;
    font-weight: 400;
    line-height: 1.219rem;
    text-align: center;
    z-index: 29;
}
.text-11 {
    position: relative;
    color: #d3d9d5;
    font-family:  sans-serif,'Montserrat';
    font-size: 1.25rem;
    font-weight: 700;
    line-height: 1.5rem;
    text-align: center;
}
.text-12 {
    position: relative;
    color: #d3d9d5;
    font-family:  sans-serif,'Montserrat';
    font-size: 1rem;
    font-weight: 400;
    line-height: 1.219rem;
    text-align: center;
}
.group-7 {
    flex-shrink: 0;
    position: relative;
    width: 19.9375rem;
    height: 14.9375rem;
    background: rgba(255, 255, 255, 0.05);
    z-index: 31;
    border-radius: 1.25rem;
}
.box-6 {
    position: relative;
    width: 2.4375rem;
    height: 2.4375rem;
    margin: 1.3125rem 0 0 8.75rem;
    z-index: 32;
    overflow: hidden;
}
.box-7 {
    position: relative;
    width: 1.828125rem;
    height: 2.1139375rem;
    margin: 0.3046875rem 0 0 0.3046875rem;
    z-index: 33;
    overflow: visible auto;
}
.img-9 {
    position: absolute;
    width: 90rem;
    height: 38.75rem;
    top: 0;
    left: 50%;
    background: url(./images/img-8.png)
        no-repeat center;
    background-size: cover;
    transform: translate(-50%, 0);
}
.pic-a {
    position: relative;
    width: 1.828125rem;
    height: 1.828125rem;
    margin: 0.3046875rem 0 0 0.3046875rem;
    background: url(./images/pic-a.png)
        no-repeat center;
    background-size: 100% 100%;
    z-index: 40;
}

.pic-9 {
    position: relative;
    width: 1.828125rem;
    height: 1.828125rem;
    margin: 0 0 0 0;
    background: url(./images/img-6.png)
        no-repeat center;
    background-size: 100% 100%;
    z-index: 35;
}

.group-8 {
    position: relative;
    width: 17.5625rem;
    height: 7.6875rem;
    margin: 1.1875rem 0 0 1.1875rem;
    font-family:  sans-serif,'Montserrat';
    font-size: 1rem;
    font-weight: 400;
    line-height: 1.219rem;
    text-align: center;
    z-index: 36;
}
.pic-a {
    position: relative;
    width: 1.828125rem;
    height: 1.828125rem;
    margin: 0.3046875rem 0 0 0.3046875rem;
    background: url(./images/img-10.png)
        no-repeat center;
    background-size: 100% 100%;
    z-index: 40;
}
.group-9 {
    flex-shrink: 0;
    position: relative;
    width: 19.9375rem;
    height: 14.9375rem;
    background: rgba(255, 255, 255, 0.05);
    z-index: 38;
    border-radius: 1.25rem;
}
.text-13 {
    position: relative;
    color: #d3d9d5;
    font-family:  sans-serif,'Montserrat';
    font-size: 1.25rem;
    font-weight: 700;
    line-height: 1.5rem;
    text-align: center;
}
.text-14 {
    position: relative;
    color: #d3d9d5;
    font-family:  sans-serif,'Montserrat';
    font-size: 1rem;
    font-weight: 400;
    line-height: 1.219rem;
    text-align: center;
}
.box-8 {
    position: relative;
    width: 17.5625rem;
    height: 7.6875rem;
    margin: 1.1875rem 0 0 1.1875rem;
    font-family:  sans-serif,'Montserrat';
    font-size: 1rem;
    font-weight: 400;
    line-height: 1.219rem;
    text-align: center;
    z-index: 41;
}
.text-15 {
    position: relative;
    color: #d3d9d5;
    font-family:  sans-serif,'Montserrat';
    font-size: 1.25rem;
    font-weight: 700;
    line-height: 1.5rem;
    text-align: center;
}
.text-16 {
    position: relative;
    color: #d3d9d5;
    font-family:  sans-serif,'Montserrat';
    font-size: 1rem;
    font-weight: 400;
    line-height: 1.219rem;
    text-align: center;
}
.text-17 {
    display: flex;
    align-items: center;
    justify-content: center;
    position: relative;
    width: 21.625rem;
    height: 3.375rem;
    margin: 0.5rem 0 0 23.3125rem;
    color: #d3d9d5;
    font-family:  sans-serif,'Montserrat';
    font-size: 1rem;
    font-weight: 300;
    line-height: 1.219rem;
    text-align: center;
    text-decoration: underline;
    z-index: 23;
}
.wrapper-a {
    position: relative;
    width: 90rem;
    height: 13rem;
    margin: 0 0 0 0;
    background: #000000;
    z-index: 43;
}
.box-9 {
    position: absolute;
    width: 23.68%;
    height: 68.75%;
    top: 15.87%;
    left: 6.53%;
    z-index: 48;
}
.wrapper-3 {
    position: relative;
    align-items: center;
    justify-content: center;
    width: 14.375rem;
    height: 3.4375rem;
    /* margin: 7.1875rem 0 0 37.8125rem; */
    background: rgba(217, 217, 217, 0.15);
    z-index: 72;
    border-radius: 7.25rem;
}
.wrapper-30 {
    position: relative;
    align-items: center;
    justify-content: center;
    width: 14.375rem;
    height: 3.4375rem;
    z-index: 72;
}
.text-9 {
    display: flex;
    align-items: center;
    justify-content: center;
    position: absolute;
    width: 8.9375rem;
    height: 3.375rem;
    top: 0.0625rem;
    left: 1.4375rem;
    color: #ffffff;
    font-family:  sans-serif,'Montserrat';
    font-size: 24px !important;
    font-weight: 400;
    line-height: 1.8285rem;
    text-align: center;
    z-index: 73;
}
.text-90 {
    display: flex;
    align-items: center;
    justify-content: center;
    position: absolute;
    width: 8.9375rem;
    height: 3.375rem;
    top: 0.0625rem;
    left: 1.4375rem;
    line-height: 1.8285rem;
    text-align: center;
    z-index: 73;
}
.group-a {
    position: relative;
    width: 2.4375rem;
    height: 2.4375rem;
    margin: 1.3125rem 0 0 8.75rem;
    z-index: 39;
    overflow: hidden;
}
.pic-3 {
    position: absolute;
    width: 2.5rem;
    height: 2.5rem;
    top: 0.5rem;
    left: 10.6875rem;
    background: url(./images/pic-3.png)
        no-repeat center;
    background-size: cover;
    z-index: 74;
    overflow: hidden;
}
.pic-30 {
    position: absolute;
    width: 2.5rem;
    height: 2.5rem;
    top: 0.5rem;
    left: 10.6875rem;
    z-index: 74;
    overflow: hidden;
}
.pic-s {
    position: relative;
    width: 2.03125rem;
    height: 2.03125rem;
    margin: 0.203125rem 0 0 0.203125rem;
     background: url(./images/img_ma.png)
        no-repeat center; 
    background-size: 100% 100%;
    z-index: 28;
}

.about-text{
color: #D3D9D5;
text-align: center;
font-family:  sans-serif,'Montserrat';
font-size: 1.25rem;
font-style: normal;
font-weight: 400;
line-height: normal;
}
.fs-Connect {
    color: #D3D9D5;
    font-family:  sans-serif,'Montserrat';
    font-size: 43px !important;
    font-weight: 500 !important;
    position: relative;
    z-index: 17 !important;
}
.fs-explore {
    color: #D3D9D5;
    font-family:  sans-serif,'Montserrat';
    font-size: 17px !important;
    font-weight: 500 !important;
    position: relative;
    z-index: 17 !important;
}
.h1, h1 {
    font-size: 2.4375rem !important;
}
.Carousel_h1
{
    color: #D3D9D5;
    font-size: 1.7rem !important;
     font-family:  sans-serif,'Montserrat';
      font-weight: 700;
       word-wrap: break-word;
       margin-left: 10%;
}
.Carousel_h6 {
    white-space: normal;
    color: #D3D9D5;
    font-family:  sans-serif,'Montserrat';
    font-size: 1.5rem;
    font-style: normal;
    text-align: left; /* Avoid justification to prevent extra spacing */
    font-weight: 500;
    line-height: 1.4; /* Adjust as needed */
    word-wrap: break-word;
    margin-block-start: 0em !important;
    margin-block-end: 0em !important;
    --bs-gutter-x: 0em !important;
    margin-right: 0em !important;
    margin-left: 10% !important;
    max-width: 80%; /* Set max width to control wrapping */
}


.Carousel_img {
    filter: brightness(75%);
    border-Radius: 1.25rem;
    flex-shrink: 0;
    border-radius: 1.25rem;
    color: #D3D9D5;
    text-align: justify;
    font-family:  sans-serif,'Montserrat';
    font-size: 1.5rem;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
     /* background: lightgray 0rem -3.25rem / 100% 114.76% no-repeat; */
    }
    .btn_get_started {
        position: absolute;
        right: 0;
        bottom: 0;
        left: 0;
        z-index: 2;
        display: flex;
        justify-content: center;
        padding: 0;
        margin-right: 15%;
        margin-bottom: 1rem;
        margin-left: 15%;
      }
      /* .align-items-centerl {
        align-items: center !important;
        display: flex;
    } */
    .feature_title
    {
        color: #D3D9D5;
        text-align: center;
        font-family:  sans-serif,'Montserrat';
        font-size: 1.25rem;
        font-style: normal;
        font-weight: 700;
        line-height: '1.21875rem';
    }
    .feature_text
    {
        color: #D3D9D5;
        text-align: center;
        font-family:  sans-serif,'Montserrat';
        font-size: 16px !important;
        font-style: normal;
        font-weight: 400;
        line-height: 1.21875rem;
    }
    .PortfolioAnalytics
    {
        width: 18.25rem; height: 3.375rem; text-align: center; color: #D3D9D5; font-size: 1.25rem; font-family:  sans-serif,'Montserrat'; font-weight: 700; word-wrap: break-word;
    }
   .PortfolioAnalyticsTool
   {
   color: #D3D9D5; font-size: 1rem; font-family:  sans-serif,'Montserrat'; font-weight: 400; word-wrap: break-word;
   }
   .Features
   {
    width: 6.3125rem; height: 1.875rem; color: #D3D9D5; font-size: 1.25rem; font-family:  sans-serif,'Montserrat'; font-weight: 700; word-wrap: break-word;
   }
   .explore-trend-runner,
.explore-trend-runner * {
    box-sizing: border-box;
}

.explore-trend-runner {
    height: 36.75rem;
    position: relative;
}

.rectangle-13 {
    background: rgba(255, 255, 255, 0.05);
    border-radius: 1.25rem;
    width: 100%;
    height: 100%;
    position: absolute;
    right: 0%;
    left: 0%;
    bottom: 0%;
    top: 0%;
}

.portfolio-analytics {
    color: #d3d9d5;
    text-align: center;
    font-family: 'Montserrat-Bold', sans-serif;
    font-size: 1.25rem;
    font-weight: 700;
    position: relative;
    right: 36.57%;
    left: 36.66%;
    width: 26.76%;

    height: 9.18%;
    display: flex;
    align-items: center;
    justify-content: center;
}

.portfolio-analytics-tool-helps-you-stay-on-top-of-the-market-by-allowing-you-to-create-and-manage-your-own-portfolio-track-daily-market-movers-and-understand-market-sentiment-at-a-glance {
    color: #d3d9d5;
    text-align: center;
    font-family: '-', sans-serif;
    font-size: 1rem;
    font-weight: 400;
    position: absolute;
    right: 4.03%;
    left: 4.12%;
    width: 91.84%;
    bottom: 63.78%;
    top: 27.04%;
    height: 9.18%;
    display: flex;
    align-items: center;
    justify-content: center;
}

.portfolio-analytics-tool-helps-you-stay-on-top-of-the-market-by-allowing-you-to-create-and-manage-your-own-portfolio-track-daily-market-movers-and-understand-market-sentiment-at-a-glance-span {
    color: #d3d9d5;
    font-family: 'Montserrat-Regular', sans-serif;
    font-size: 1rem;
    font-weight: 400;
}

.portfolio-analytics-tool-helps-you-stay-on-top-of-the-market-by-allowing-you-to-create-and-manage-your-own-portfolio-track-daily-market-movers-and-understand-market-sentiment-at-a-glance-span2 {
    color: #d3d9d5;
    font-family: 'Montserrat-Bold', sans-serif;
    font-size: 1rem;
    font-weight: 700;
}

.features {
    color: #d3d9d5;
    text-align: left;
    font-family: 'Montserrat-Bold', sans-serif;
    font-size: 1.25rem;
    font-weight: 700;
    position: absolute;
    right: 45.37%;
    left: 45.37%;
    width: 9.26%;
    bottom: 55.61%;
    top: 39.29%;
    height: 5.1%;
    display: flex;
    align-items: center;
    justify-content: flex-start;
}

.explore-features {
    color: #d3d9d5;
    text-align: center;
    font-family: 'Montserrat-Light', sans-serif;
    font-size: 1rem;
    font-weight: 300;
    text-decoration: underline;
    position: absolute;
    right: 34.1%;
    left: 34.19%;
    width: 31.71%;
    bottom: 3.23%;
    top: 87.59%;
    height: 9.18%;
    display: flex;
    align-items: center;
    justify-content: center;
}

.vector {
    width: 9.17%;
    height: 11.56%;
    position: absolute;
    right: 45.37%;
    left: 45.46%;
    bottom: 84.18%;
    top: 4.25%;
    overflow: visible;
}

.manage-portfolio {
    position: absolute;
    inset: 0;
}

.rectangle-14 {
    background: rgba(255, 255, 255, 0.05);
    border-radius: 1.25rem;
    width: 29.24%;
    height: 40.65%;
    position: absolute;
    right: 65.99%;
    left: 4.77%;
    bottom: 13.78%;
    top: 45.58%;
}

.tdesign-chart-analytics {
    width: 2.4375rem;
    height: 2.4375rem;
    position: absolute;
    left: 12rem;
    top: 18.0625rem;
    overflow: visible;
}

.create-and-manage-your-own-portfolio-build-and-monitor-your-personalized-investment-portfolio-with-ease {
    color: #d3d9d5;
    text-align: center;
    position: absolute;
    right: 67.74%;
    left: 6.51%;
    width: 25.76%;
    bottom: 15.99%;
    top: 59.01%;
    height: 25%;
    display: flex;
    align-items: center;
    justify-content: center;
}

.create-and-manage-your-own-portfolio-build-and-monitor-your-personalized-investment-portfolio-with-ease-span {
    color: #d3d9d5;
    font-family: 'Montserrat-Bold', sans-serif;
    font-size: 1.25rem;
    font-weight: 700;
}

.create-and-manage-your-own-portfolio-build-and-monitor-your-personalized-investment-portfolio-with-ease-span2 {
    color: #d3d9d5;
    font-family: 'Montserrat-Regular', sans-serif;
    font-size: 1rem;
    font-weight: 400;
}

.moving-today {
    position: absolute;
    inset: 0;
}

.rectangle-142 {
    background: rgba(255, 255, 255, 0.05);
    border-radius: 1.25rem;
    width: 29.24%;
    height: 40.65%;
    position: absolute;
    right: 35.38%;
    left: 35.38%;
    bottom: 13.78%;
    top: 45.58%;
}

.mingcute-performance-line {
    width: 2.4375rem;
    height: 2.4375rem;
    position: absolute;
    left: 32.875rem;
    top: 18.0625rem;
    overflow: hidden;
}

.group {
    width: 75%;
    height: 86.73%;
    position: absolute;
    right: 12.5%;
    left: 12.5%;
    bottom: 0.77%;
    top: 12.5%;
    overflow: visible;
}

.what-is-moving-today-get-real-time-insights-on-the-top-performing-stocks-and-the-biggest-market-movers {
    color: #d3d9d5;
    text-align: center;
    position: absolute;
    right: 37.12%;
    left: 37.12%;
    width: 25.76%;
    bottom: 20.07%;
    top: 59.01%;
    height: 20.92%;
    display: flex;
    align-items: center;
    justify-content: center;
}

.what-is-moving-today-get-real-time-insights-on-the-top-performing-stocks-and-the-biggest-market-movers-span {
    color: #d3d9d5;
    font-family: 'Montserrat-Bold', sans-serif;
    font-size: 1.25rem;
    font-weight: 700;
}

.what-is-moving-today-get-real-time-insights-on-the-top-performing-stocks-and-the-biggest-market-movers-span2 {
    color: #d3d9d5;
    font-family: 'Montserrat-Regular', sans-serif;
    font-size: 1rem;
    font-weight: 400;
}

.rectangle-143 {
    background: rgba(255, 255, 255, 0.05);
    border-radius: 1.25rem;
    width: 29.24%;
    height: 40.65%;
    position: absolute;
    right: 4.77%;
    left: 65.99%;
    bottom: 13.78%;
    top: 45.58%;
}

.majesticons-analytics {
    width: 2.4375rem;
    height: 2.4375rem;
    position: absolute;
    left: 53.75rem;
    top: 18.0625rem;
    overflow: visible;
}

.market-sentiment-gauge-how-the-market-feels-about-different-stocks-and-sectors-with-sentiment-analysis {
    color: #d3d9d5;
    text-align: center;
    position: absolute;
    right: 6.51%;
    left: 67.74%;
    width: 25.76%;
    bottom: 20.07%;
    top: 59.01%;
    height: 20.92%;
    display: flex;
    align-items: center;
    justify-content: center;
}

.market-sentiment-gauge-how-the-market-feels-about-different-stocks-and-sectors-with-sentiment-analysis-span {
    color: #d3d9d5;
    font-family: 'Montserrat-Bold', sans-serif;
    font-size: 1.25rem;
    font-weight: 700;
}

.market-sentiment-gauge-how-the-market-feels-about-different-stocks-and-sectors-with-sentiment-analysis-span2 {
    color: #d3d9d5;
    font-family: 'Montserrat-Regular', sans-serif;
    font-size: 1rem;
    font-weight: 400;
}
.img-fluid {
    width: 6.25rem; height: 4.25rem;
}
.img-main-fluid {
    width: 11.813rem; height: 4.063rem; justify-content: center; align-items: center; display: inline-flex;
}
.img-footer-fluid {
    width: 21rem; height: 7.188rem; justify-content: center; align-items: center; display: inline-flex;
}
.card {
    width: 19.9375rem; height: 14.9375rem; background: rgba(255, 255, 255, 0.05); border-radius: 1.25rem;
}
.featurescss
{
    background: rgba(255, 255, 255, 0.05);
     border-radius: 1.25rem;
}
.carousel-indicators [data-bs-target] {
    width: 10px;
  height: 10px;
  border-radius: 100%;
}
.card_gap{
    margin-right:5px!important;
    }
      /* Tablets */
  /* @media (max-width: 1024px) {
    .container-fluid {
      padding: 15px;
      font-size: 0.75rem;
      text-align: center;
      display:    block;
    }
  } */
  
  /* Mobile */
  /* @media (max-width: 768px) {
    .container-fluid {
      padding: 10px;
      font-size: 0.5rem;
      text-align: center !important;
      justify-content: center;
      display:    block;
    }
  } */
  .browser {
    margin: 0 !important;
    padding: 0 !important;
    height: auto !important;
  }
  .font-family-sans-serif {
  font-family:sans-serif,'Montserrat' !important;
}