.home {
    display: flex;
    flex-direction: column;
    min-height: 100vh;
  }
  
  .landing {
    flex: 1;
    padding: 0px; /* Add padding if needed */
  }
  
  footer {
    background: #333;
    color: #fff;
    text-align: center;
    padding: 1rem;
    width: 100%;
  }
 .loginbg{
   top: 20px;
   textAlign: center; 
   width: 100%;
   background: linear-gradient(to bottom right, black, #282727);
   background: linear-gradient(to bottom, transparent 1%, black 100%);
 }
           