/* Ensure the layout takes the full height of the viewport */
.layout {
    display: flex;
    flex-direction: column;
    min-height: 100vh;
    display: table;
    table-layout: fixed;
    width: 100%;
    height: 100%;
    overflow: hidden;
    position: relative;
    @media only screen and (min-width : 320px) {
      display: table;
      table-layout: fixed;
      overflow: hidden;
      position: relative;
    }

    /* Extra Small Devices, Phones */ 
    @media only screen and (min-width : 480px) {
      display: table;
      table-layout: fixed;
      overflow: hidden;
      position: relative;
    }

    /* Small Devices, Tablets */
    @media only screen and (min-width : 768px) {
      display: table;
      table-layout: fixed;
      overflow: hidden;
      position: relative;
    }
   

  }
  
  /* Main content should expand to take the available space */
  .content {
    flex: 1;
    padding: 0; /* Add padding if needed */
  }
  
  /* Footer styles */
  footer {
    background: #333;
    color: #fff;
    text-align: center;
    padding: 1rem;
    width: 100%;
  }
  